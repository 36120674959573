









































import Vue from 'vue';
import Order from '@/models/Order';
export default Vue.extend({
  name: 'TodaysOrders',
  props: ['todaysOrders', 'activeOrderId', 'filterIsReady'],
  methods: {
    toggleFilterIsReady() {
      this.$emit('didChangeIsReadyFilter', !this.filterIsReady);
    },
    getAdditionalFieldValue(order: Order, index: number) {
      if (index >= order.additionalValues.length) {
        return '';
      }
      let value = order.additionalValues[index];
      return value.value;
    }
  }
});
