








































































import Vue from 'vue';
import LoadingInstruction from '@/models/LoadingInstruction';

var MIN_WIDTH = 10;

export default Vue.extend({
  name: 'FlybarCountValue',
  props: {
    currentItem: Object as () => LoadingInstruction,
    enabled: Boolean
  },
  data() {
    return {
      currentCount: 1
    };
  },
  watch: {
    currentItem(newValue) {
      this.currentCount = newValue.currentFlybarPartCount;
    }
  },
  methods: {
    setCount: function (number: number) {
      this.$emit('setCountOnFlybar', number);
    }
  },
  computed: {
    otherFlybarCount: function (): number {
      return this.currentItem?.totalPartCount - this.currentItem?.currentFlybarPartCount;
    },

    otherFlybarCountWidth: function (): number {
      if (this.otherFlybarCount === 0) {
        return 0;
      }
      return Math.max(MIN_WIDTH, (100 / this.currentItem?.orderPartCount) * this.otherFlybarCount);
    },

    currentFlybarCount: function (): number {
      return this.currentItem.currentFlybarPartCount;
    },

    currentFlybarCountWidth: function (): number {
      if (this.currentFlybarCount == 0) {
        return 0;
      }
      return Math.max(
        MIN_WIDTH,
        (100 / this.currentItem?.orderPartCount) * this.currentFlybarCount
      );
    },

    openCount: function (): number {
      return this.currentItem?.orderPartCount - this.currentItem?.totalPartCount;
    },

    openCountWidth: function (): number {
      return Math.max(100 - this.otherFlybarCountWidth - this.currentFlybarCountWidth, 0);
    }
  }
});
