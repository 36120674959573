import axios from 'axios';
import {
  Column,
  Config,
  FieldType,
  toDateString,
  toDateTimeString,
  ValidationResult
} from 'rey-vue-smarttable';
import Color from '@/models/Color';
import { RetentionPeriodDropdown } from '@/models/RetentionPeriod';
import FieldDefinition from '@/models/FieldDefinition';
import UiControlType from '@/models/UiControlType';
import EntityType from '@/models/EntityType';
import i18n from '@/languages/i18n';
import Modality, { modalityDisplayTitle } from '@/models/Modality';

class FieldDefinitionLoader {
  private DynamicFieldPrefix = 'dynamic_';

  public async loadDynamicColumnDefinitionsToSmartTableConfig(
    entityType: EntityType,
    smartTableConfig: Config,
    isEditable: boolean
  ) {
    const colors = await this.loadColors();
    const modalities = await this.loadModalities();
    const response = await axios.get('/api/FieldDefinitions', {
      params: {
        equals: 'EntityType=' + entityType,
        orderBy: 'sortOrder'
      }
    });
    this.mergeColumns(response.data.entities, smartTableConfig, isEditable, colors, modalities);
  }

  private mergeColumns(
    fieldDefinitions: FieldDefinition[],
    smartTableConfig: Config,
    isEditable: boolean,
    colors: Color[],
    modalities: Modality[]
  ) {
    const dynamicColumns = fieldDefinitions
      .filter((x) => x.uiControlType != UiControlType.None)
      .map((x) => this.getColumnFromFieldDefinition(x, colors, modalities));

    if (smartTableConfig.columns) {
      smartTableConfig.columns = smartTableConfig.columns.filter(
        (c) => c.fieldName.indexOf(this.DynamicFieldPrefix) !== 0
      );
      for (const column of dynamicColumns) {
        column.ordering = smartTableConfig.columns.length;
        const smartTableColumn = new Column(column);
        smartTableColumn.editable = () => isEditable;
        smartTableConfig.columns.push(smartTableColumn);
      }
    } else {
      smartTableConfig.columns = dynamicColumns;
    }
  }

  private getColumnFromFieldDefinition(
    fieldDefinition: FieldDefinition,
    colors: Color[],
    modalities: Modality[]
  ): Column {
    return new Column({
      title: i18n.t(fieldDefinition.displayNameResourceId).toString(),
      description: i18n.t(fieldDefinition.descriptionResourceId).toString(),
      fieldName: this.DynamicFieldPrefix + fieldDefinition.fieldDefinitionId,
      fieldType: this.getUiControlType(fieldDefinition.uiControlType),
      editable: () => !fieldDefinition.isSystemRelevant,
      dropdownOptions: this.getDropdownOptions(fieldDefinition.uiControlType, colors, modalities),
      validator: (value) => this.isValidValue(value, fieldDefinition),
      hidden: fieldDefinition.isDisabled,
      hiddenInAddDialog: fieldDefinition.isDisabled,
      converter: (value) => this.convert(value, fieldDefinition.uiControlType, modalities)
    });
  }

  // eslint-disable-next-line
  private convert(value: any, uiControlType: UiControlType, modalities: Modality[]): string {
    switch (uiControlType) {
      case UiControlType.Numeric:
      case UiControlType.Boolean:
      case UiControlType.RetentionPeriod:
      case UiControlType.ColorDropdown:
      case UiControlType.Text:
        return value;
      case UiControlType.DateOnly:
        return toDateString(value);
      case UiControlType.Timestamp:
        return toDateTimeString(value);
      case UiControlType.Modality:
      case UiControlType.None:
        return value;
      default:
        return value;
    }
  }

  private mapModalityToDisplayName(modality: Modality | undefined) {
    return modality ? modalityDisplayTitle(modality) : '';
  }

  private getUiControlType(uiControlType: UiControlType): FieldType {
    switch (uiControlType) {
      case UiControlType.Numeric:
        return 'numeric';
      case UiControlType.Boolean:
        return 'boolean';
      case UiControlType.RetentionPeriod:
      case UiControlType.Modality:
      case UiControlType.ColorDropdown:
        return 'dropdown';
      case UiControlType.Text:
      case UiControlType.DateOnly:
      case UiControlType.Timestamp:
      case UiControlType.Icons:
      case UiControlType.None:
        return 'string';
      default:
        return 'string';
    }
  }

  private getDropdownOptions(
    uiControlType: UiControlType,
    colors: Color[],
    modalities: Modality[]
  ) {
    switch (uiControlType) {
      case UiControlType.Text:
      case UiControlType.Numeric:
      case UiControlType.Boolean:
      case UiControlType.None:
        return undefined;
      case UiControlType.RetentionPeriod:
        return RetentionPeriodDropdown();
      case UiControlType.ColorDropdown:
        return colors.map((x) => ({
          id: x.colorCode,
          title: x.colorCode
        }));
      case UiControlType.Modality:
        return modalities.map((x) => ({
          id: x.modalityId,
          title: this.mapModalityToDisplayName(x)
        }));
      default:
        return undefined;
    }
  }

  // eslint-disable-next-line
  public isValidValue(value: any, fieldDefinition: FieldDefinition): ValidationResult {
    switch (fieldDefinition.uiControlType) {
      case UiControlType.Text:
        return {
          isValid:
            value != null && value != undefined && value.length < fieldDefinition.stringMaxLength,
          errorMessages: [
            i18n
              .t('validationErrors.invalidTextLength', {
                currentValue: value,
                maxLength: fieldDefinition.stringMaxLength
              })
              .toString()
          ]
        };
      case UiControlType.Numeric:
        return {
          isValid:
            value != null &&
            value != undefined &&
            value != '' &&
            value >= fieldDefinition.numberMinValue &&
            value <= fieldDefinition.numberMaxValue,
          errorMessages: [
            i18n
              .t('validationErrors.invalidNumberRange', {
                currentValue: value,
                minValue: fieldDefinition.numberMinValue,
                maxValue: fieldDefinition.numberMaxValue
              })
              .toString()
          ]
        };
      case UiControlType.Boolean:
      case UiControlType.RetentionPeriod:
      case UiControlType.ColorDropdown:
      case UiControlType.Modality:
      case UiControlType.Icons:
      case UiControlType.None:
        return {
          isValid: true,
          errorMessages: []
        };
      default:
        return {
          isValid: false,
          errorMessages: [
            i18n
              .t('validationErrors.invalidControlType', {
                currentValue: value
              })
              .toString()
          ]
        };
    }
  }
  async loadColors(): Promise<Color[]> {
    const response = await axios.get('/api/Color', {
      params: {
        orderBy: 'colorCode'
      }
    });
    return response.data.entities;
  }

  async loadModalities(): Promise<Modality[]> {
    const response = await axios.get('/api/Modality', {
      params: {
        orderBy: 'ModalityId'
      }
    });
    return response.data.entities;
  }
}

export default new FieldDefinitionLoader();
