



























import LoadingInstruction from '@/models/LoadingInstruction';
import Vue from 'vue';

export default Vue.extend({
  name: 'CurrentFlybar',
  props: ['activeInstructions', 'lastBarcodeInput', 'flybarNumber'],
  methods: {
    getAdditionalFieldValue(instruction: LoadingInstruction, index: number) {
      if (index >= instruction.sideAdditionalValues.length) {
        return '';
      }
      let value = instruction.sideAdditionalValues[index];
      return value.value;
    }
  }
});
