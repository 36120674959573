// tslint:disable-next-line: class-name
export default interface Modality {
  modalityId: string;
  group: string;
  name: string;
}

export function modalityDisplayTitle(modality: Modality): string {
  return `${modality.group} - ${modality.name}`;
}
