















































































import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import $ from 'jquery';
import { Sidebar, SidebarGroup, SidebarEntry, Topbar } from 'rey-smartmes-ui';
import StationLoader from '@/router/StationLoader';
import SystemParameterService from '@/services/SystemParameterService';
import { Route } from 'vue-router';
import LanguageSelector from '@/LanguageSelector.vue';
import AdminLogin from '@/components/AdminLogin.vue';

@Component({
  components: {
    AdminLogin,
    Sidebar,
    Topbar,
    LanguageSelector
  }
})
export default class App extends Vue {
  public isSystemAdminEnabled = false;
  public instructionAppPath = '';
  // Data properties
  private systemAdminGroups: SidebarGroup[] = [
    new SidebarGroup(this.$t('navigationGroups.systemadmin').toString(), [
      new SidebarEntry(
        this.$t('views.systemadmin.systemParameters.title').toString(),
        'fas fa-wrench',
        '/systemadmin/systemParameters'
      ),
      new SidebarEntry(
        this.$t('views.systemadmin.stations.title').toString(),
        'fas fa-tv',
        '/systemadmin/stations'
      ),
      new SidebarEntry(
        this.$t('views.systemadmin.counters.title').toString(),
        'fas fa-stopwatch-20',
        '/systemadmin/counters'
      ),
      new SidebarEntry(
        this.$t('views.systemadmin.fieldDefinitions.title').toString(),
        'fas fa-draw-polygon',
        '/systemadmin/fielddefinitions'
      ),
      new SidebarEntry(
        this.$t('views.systemadmin.messageTypes.title').toString(),
        'fas fa-file-export',
        '/systemadmin/messagetypes'
      ),
      new SidebarEntry(
        this.$t('views.systemadmin.localizations.title').toString(),
        'fas fa-language',
        '/systemadmin/localizations'
      )
    ])
  ];
  public sidebarGroups: SidebarGroup[] = [
    new SidebarGroup(this.$t('navigationGroups.admin').toString(), [
      new SidebarEntry(
        this.$t('views.admin.colors.title').toString(),
        'fas fa-palette',
        '/admin/colors'
      ),
      new SidebarEntry(
        this.$t('views.admin.modalities.title').toString(),
        'fas fa-clipboard',
        '/admin/modalities'
      ),
      new SidebarEntry(
        this.$t('views.admin.coatings.title').toString(),
        'fas fa-bars',
        '/admin/coatings'
      ),
      new SidebarEntry(
        this.$t('views.admin.articles.title').toString(),
        'fas fa-cogs',
        '/admin/articles'
      ),
      new SidebarEntry(
        this.$t('views.admin.orders.title').toString(),
        'fas fa-file-alt',
        '/admin/orders'
      ),
      new SidebarEntry(
        this.$t('views.admin.geometryColorParameter.title').toString(),
        'fas fa-robot',
        '/admin/geometryColorParameter'
      )
    ]),
    new SidebarGroup(this.$t('navigationGroups.reporting').toString(), [
      new SidebarEntry(
        this.$t('views.reporting.zoneLogs.title').toString(),
        'fab fa-buromobelexperte',
        '/reporting/zoneLogs'
      )
    ])
  ];

  // Lifecycle hooks
  public async created(): Promise<void> {
    const embeddedDashboardLink = await SystemParameterService.getSystemParameterString(
      'EmbeddedDashboardLink'
    );

    if (embeddedDashboardLink || embeddedDashboardLink !== '') {
      this.sidebarGroups[1].sidebarEntries.push(
        new SidebarEntry(
          this.$t('views.reporting.dashboard.title').toString(),
          'fas fa-chart-pie',
          '/reporting/dashboard'
        )
      );
    }

    const passwordsEnabled = await SystemParameterService.getSystemParameterBool('UsePasswords');
    if (passwordsEnabled) {
      this.sidebarGroups[0].sidebarEntries.push(
        new SidebarEntry(
          this.$t('views.admin.authentications.title').toString(),
          'fas fa-lock',
          '/admin/authentications'
        )
      );
    }

    this.instructionAppPath = await SystemParameterService.getSystemParameterString(
      'ExternalAppPath'
    );
  }

  @Watch('$route', { immediate: true, deep: true })
  public async onUrlChange(newVal: Route): Promise<void> {
    if (newVal.query.systemAdminNav === 'true') {
      this.isSystemAdminEnabled = true;
      this.$router.push({ query: { systemAdminNav: [] } });
    }
  }

  public async mounted(): Promise<void> {
    this.hideNavigation();
    this.disableNavigationToggle();

    await StationLoader.Instance()
      .loadStations()
      .then((stationRoutes) => {
        if (stationRoutes) {
          stationRoutes.forEach((route) => this.$router.addRoute(route));
          const sideBarGroup = StationLoader.Instance().getSidebarGroup();
          if (
            sideBarGroup.sidebarEntries.length !== 0 &&
            this.sidebarGroups.filter((s) => s.name == sideBarGroup.name).length === 0
          ) {
            this.sidebarGroups.push(sideBarGroup);
          }
        }
      });

    if (process.env.NODE_ENV === 'development') {
      $('#sidebar>.c-sidebar-brand>img').on('dblclick', () => {
        const query = Object.assign({}, this.$route.query);
        if (query.systemAdminNav !== 'true') {
          query.systemAdminNav = 'true';
          this.$router.push({ query });
        }
      });
    }
  }

  public async beforeUpdate(): Promise<void> {
    if (!this.isNavigationHidden()) {
      this.showNavigation();
      this.enableNavigationToggle();
      if (this.isSystemAdminEnabled && this.sidebarGroups.indexOf(this.systemAdminGroups[0]) < 0) {
        this.sidebarGroups = this.systemAdminGroups.concat(this.sidebarGroups);
      }
    }
  }

  // private methods
  public isNavigationHidden(): boolean {
    return this.$route.query.hideNav === 'true';
  }

  private hideNavigation(): void {
    $('#sidebar').removeClass('c-sidebar-lg-show');
  }

  private showNavigation(): void {
    $('#sidebar').addClass('c-sidebar-lg-show');
  }

  private disableNavigationToggle(): void {
    $('.c-header-toggler').prop('disabled', true);
    $('.router-link-active').css('pointer-events', 'none');
  }

  private enableNavigationToggle(): void {
    $('.c-header-toggler').prop('disabled', false);
    $('.router-link-active').css('pointer-events', 'auto');
  }
}
