



















import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import axios from 'axios';
import StationType from '@/models/StationType';
import StationParameter from '@/models/StationParameter';

@Component({
  components: {}
})
export default class Dashboard extends Vue {
  // Data properties
  public dashboardLink = '';
  public isLoading = true;
  public stationParameters = [] as StationParameter[];

  // Lifecycle hooks
  public async mounted(): Promise<void> {
    await this.loadStationParameters();
  }

  public disableKeyForwarding(): void {
    try {
      const grafanaIframe = this.$refs.grafana as HTMLIFrameElement;
      grafanaIframe?.contentWindow?.addEventListener(
        'keydown',
        function (event) {
          const escapeKey = '27'; // 'Esc'
          if (event.key == escapeKey) {
            event.preventDefault();
            event.stopPropagation();
            return false;
          }
        },
        true
      );
    } catch {
      // this might happen if the iframe is cross origin where this is not possible - in this case keys will still work
    }
  }

  public onLoadingFinished(): void {
    this.disableKeyForwarding();
    this.isLoading = false;
  }

  private async loadStationParameters() {
    await axios
      .post('/api/actions/GetStationParameters/', {
        StationNumber: this.currentStationNumber,
        StationType: StationType.Grafana
      })
      .then((response) => (this.stationParameters = response.data))
      .then((result) => this.loadGrafanaUrl(result))
      .finally(() => (this.isLoading = false));
  }

  private loadGrafanaUrl(stationParameters: StationParameter[]) {
    const stationParameter = stationParameters.find(
      (x) => x.parameterDefinitionId === 'GrafanaDashboardUrl'
    );

    if (stationParameter) {
      this.dashboardLink = stationParameter.value;
    }
  }

  get currentStationNumber(): number {
    // @ts-ignore
    return this.$route.meta.stationNumber;
  }
}
