import i18n from '@/languages/i18n';

export enum RetentionPeriod {
  Short = 1,
  Medium = 2,
  Long = 3,
  Off = 4
}

export const RetentionPeriodDropdown = () => [
  { id: RetentionPeriod.Short, title: i18n.t('dropdowns.retentionPeriods.short').toString() },
  { id: RetentionPeriod.Medium, title: i18n.t('dropdowns.retentionPeriods.medium').toString() },
  { id: RetentionPeriod.Long, title: i18n.t('dropdowns.retentionPeriods.long').toString() },
  { id: RetentionPeriod.Off, title: i18n.t('dropdowns.retentionPeriods.off').toString() }
];

export default RetentionPeriod;
