

































































































































import { Vue } from 'vue-property-decorator';
import FieldDefinition from '@/models/FieldDefinition';
import UiControlType from '@/models/UiControlType';
import { RetentionPeriodDropdown } from '@/models/RetentionPeriod';
import FieldValue from '@/models/FieldValue';
import Modality, { modalityDisplayTitle } from '@/models/Modality';
import fieldDefinitionLoader from '@/services/FieldDefinitionLoader';

import { DxSelectBox } from 'devextreme-vue';

interface DisplayItem {
  title: string;
  displayValue: string;
}

export default Vue.extend({
  props: ['colors', 'modalities', 'instruction', 'instructionIndex'],
  name: 'RobotConfigurationItem',
  components: { DxSelectBox },
  data() {
    return {
      RetentionPeriodDropdown,
      UiControlType
    };
  },
  methods: {
    currentValue(fieldDefinition: FieldDefinition): string {
      var fieldValue = this.instruction.fieldValues.find(
        (x: FieldValue) => x.fieldDefinitionId == fieldDefinition.fieldDefinitionId
      );
      if (fieldValue) {
        return fieldValue.value;
      }
      return '';
    },

    displayTitleForModality(modality: Modality) {
      if (modality) {
        return modalityDisplayTitle(modality);
      }
      return '';
    },

    showCurrentValueAsModality(fieldDefinition: FieldDefinition): string {
      const currentValue = this.currentValue(fieldDefinition);
      if (currentValue) {
        const modality = this.modalities.find((x: Modality) => x.modalityId == currentValue);
        return modalityDisplayTitle(modality);
      } else {
        return '';
      }
    },

    onSuggestionChange(checked: boolean) {
      this.$emit('didChangeAdoptSuggestion', this.instructionIndex, checked);
    },

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    updateDxValue(event: any, fieldDefinition: FieldDefinition) {
      const targetElement = event.element.querySelector('.dx-texteditor-input');
      this.handleValueUpdate(event.value, fieldDefinition, targetElement, event);
    },

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    updateValue(event: any, fieldDefinition: FieldDefinition) {
      const value =
        event.target.type === 'checkbox'
          ? event.target.checked.toString()
          : event.target.value.toString();
      this.handleValueUpdate(value, fieldDefinition, event.target, event);
    },

    handleValueUpdate(
      value: string,
      fieldDefinition: FieldDefinition,
      element: HTMLSelectElement,
      event: Event
    ) {
      const validationResult = fieldDefinitionLoader.isValidValue(value, fieldDefinition);
      if (validationResult.isValid) {
        element.setCustomValidity('');
        element.reportValidity();
        element.classList.remove('has-error');

        this.$emit('didChangeFieldValue', this.instructionIndex, fieldDefinition, value);
      } else {
        const errorMessage = validationResult.errorMessages.join('\n');
        element.setCustomValidity(errorMessage);
        element.reportValidity();
        element.classList.add('has-error');
        event.preventDefault();
        event.stopPropagation();
        return false;
      }
    }
  },
  // eslint-enable-next-line @typescript-eslint/no-explicit-any

  computed: {
    displayItems(): DisplayItem[] {
      if (this.instruction == null) {
        return [];
      }

      return [
        {
          title: this.$t('views.stations.robotConfiguration.instruction.orderNumber'),
          displayValue: this.instruction?.orderNumber
        },
        {
          title: this.$t('views.stations.robotConfiguration.instruction.articleNumber'),
          displayValue: this.instruction?.articleNumber
        },
        {
          title: this.$t('views.stations.robotConfiguration.instruction.numberOfArticles'),
          displayValue: this.instruction?.totalPartCount.toString()
        },
        {
          title: this.$t('views.stations.robotConfiguration.instruction.coating'),
          displayValue: this.instruction?.coatingNumber
        },
        {
          title: this.$t('views.stations.robotConfiguration.instruction.geometry'),
          displayValue: this.instruction?.geometry
        }
      ];
    }
  }
});
