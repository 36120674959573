var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('content-card',{attrs:{"title":_vm.$t('views.stations.loading.plannedFlybarDetailsTitle') + _vm.item.sortOrder,"cardIcon":"fas fa-calendar-days"},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('ul',{staticClass:"list-group mb-3"},_vm._l((_vm.item.items),function(orderItem,index){return _c('li',{key:orderItem.plannedOrderTrackingDataId,class:{
          active:
            _vm.selectedPlannedOrderTrackingDataId &&
            orderItem.plannedOrderTrackingDataId === _vm.selectedPlannedOrderTrackingDataId,
          'list-group-item d-flex justify-content-between align-items-center is-selectable': true
        },on:{"click":function($event){return _vm.selectItem(orderItem)}}},[_c('div',[_c('span',{staticClass:"font-weight-bold mr-2"},[_vm._v(_vm._s(index + 1))]),_vm._v(" "+_vm._s(_vm.extractParameter(orderItem, 'WbtOrderNumber'))+" ")]),_c('span',[_vm._l((orderItem.articleWarnHintIcons),function(warnIcon){return _c('span',{key:warnIcon,staticClass:"mr-2",class:warnIcon})}),(_vm.extractParameter(orderItem, 'SectorAndTrayNumber'))?_c('span',{staticClass:"badge badge-pill badge-dark mr-2"},[_vm._v(" "+_vm._s(_vm.extractParameter(orderItem, 'SectorAndTrayNumber'))+" ")]):_vm._e(),_c('span',{staticClass:"badge badge-pill",class:_vm.stateClass(orderItem)},[(_vm.icon(orderItem))?_c('em',{class:_vm.icon(orderItem)}):_vm._e()])],2)])}),0),(_vm.additionalItems.length > 0)?[_c('small',{staticClass:"font-weight-bold mb-2"},[_vm._v(_vm._s(_vm.$t('views.stations.loading.additionalUnplannedItemsOnFlybar')))]),_c('ul',{staticClass:"list-group mb-3"},_vm._l((_vm.additionalItems),function(additionalItem){return _c('li',{key:additionalItem.orderNumber,class:{
            active: additionalItem.orderNumber === _vm.selectedAdditionalOrderNumber,
            'list-group-item d-flex justify-content-between align-items-center is-selectable': true
          },on:{"click":function($event){_vm.selectedAdditionalOrderNumber =
              _vm.selectedAdditionalOrderNumber == additionalItem.orderNumber
                ? undefined
                : additionalItem.orderNumber}}},[_vm._v(" "+_vm._s(additionalItem.orderNumber)+" ")])}),0)]:_vm._e(),(_vm.showActions)?[_c('button',{staticClass:"btn-secondary btn-block btn",attrs:{"disabled":!_vm.isCancelButtonActive},on:{"click":function($event){$event.preventDefault();return _vm.cancelOrderItem.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('views.stations.loading.cancelOrder'))+" ")]),_c('button',{staticClass:"btn-secondary btn-block btn",attrs:{"disabled":!_vm.isRemoveButtonActive},on:{"click":function($event){$event.preventDefault();return _vm.removeOrderItem.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('views.stations.loading.removeOrder'))+" ")])]:_vm._e()]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }