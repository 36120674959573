






































































import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import Instruction from '@/models/Instruction';

@Component({
  components: {}
})
export default class InstructionViewer extends Vue {
  public currentInstructionIndex = 0;

  @Prop({ default: [] })
  public instructions!: Instruction[];

  public created(): void {
    window.addEventListener('keydown', this.handleKeyDown);
  }

  public beforeDestroyed(): void {
    window.removeEventListener('keydown', this.handleKeyDown);
  }

  @Watch('instructions')
  public onInstructionsChanged(): void {
    this.currentInstructionIndex = 0;
  }

  private handleKeyDown(e: KeyboardEvent): void {
    if (e.key === 'ArrowLeft') {
      e.preventDefault();
      const elements = document.getElementsByClassName('carousel-control-prev');
      if (elements && elements.length > 0) {
        (elements[0] as HTMLAnchorElement).click();
      }
    } else if (e.key === 'ArrowRight') {
      e.preventDefault();
      const elements = document.getElementsByClassName('carousel-control-next');
      if (elements && elements.length > 0) {
        (elements[0] as HTMLAnchorElement).click();
      }
    }
  }

  public setCurrentIndex(newIndex: number): void {
    this.currentInstructionIndex = newIndex;
  }

  public setCurrentIndexOffset(offset: number): void {
    this.currentInstructionIndex =
      (this.currentInstructionIndex + offset + this.instructions.length) % this.instructions.length;
  }
}
