
















































































import Vue from 'vue';
import PlannedFlybarVisualization from '@/components/PlannedFlybarVisualization.vue';
import PlannedTrackingDataItem from '@/models/PlannedTrackingDataItem';
import axios from 'axios';
import PlannedTrackingDataDetails from '@/components/PlannedTrackingDataDetails.vue';
import ActiveMaskingInstructionDetails from '@/components/MaskingViews/SubViews/ActiveMaskingInstructionDetails.vue';
import MaskingInstruction from '@/models/MaskingInstruction';

declare interface GetPlannedTrackingDataForTrackingNumberResponse {
  data: PlannedTrackingDataItem;
}

export default Vue.extend({
  name: 'PlannedFlybarMaskingView',
  components: {
    PlannedTrackingDataDetails,
    PlannedFlybarVisualization,
    ActiveMaskingInstructionDetails
  },
  props: ['activeState', 'stationParameters', 'activeInstructions'],
  data: () => {
    return {
      isLoadingDataItem: false,
      plannedTrackingDataItem: undefined as PlannedTrackingDataItem | undefined,
      currentInstructionIndex: 0,
      selectedPlannedOrderTrackingDataItemId: undefined as undefined | string
    };
  },
  created() {
    window.addEventListener('keydown', this.handleKeyDown);
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.handleKeyDown);
  },
  methods: {
    handleKeyDown(e: KeyboardEvent) {
      if (e.key === 'ArrowUp') {
        e.preventDefault();
        this.setCurrentIndexOffset(1);
      } else if (e.key === 'ArrowDown') {
        e.preventDefault();
        this.setCurrentIndexOffset(-1);
      }
    },

    setCurrentIndexOffset(offset: number) {
      this.currentInstructionIndex =
        (this.currentInstructionIndex + offset + this.activeInstructions.length) %
        this.activeInstructions.length;
      this.updateSelectedOrderTrackingItem();
    },

    updateSelectedOrderTrackingItem() {
      const instruction = this.activeInstructions[this.currentInstructionIndex];
      if (!instruction) {
        this.selectedPlannedOrderTrackingDataItemId = undefined;
      }
      const selectedPlannedOrderItem = this.plannedTrackingDataItem?.items.find(
        (x) => x.orderId == instruction.orderId
      );

      if (selectedPlannedOrderItem) {
        this.selectedPlannedOrderTrackingDataItemId =
          selectedPlannedOrderItem.plannedOrderTrackingDataId;
      }
    },

    async loadPlannedTrackingDataItem() {
      this.isLoadingDataItem = true;
      this.plannedTrackingDataItem = undefined;
      await axios
        .post('/api/actions/GetPlannedTrackingDataForTrackingNumber/', {
          trackingNumber: this.activeState.trackingNumber
        })
        .then((response: GetPlannedTrackingDataForTrackingNumberResponse) => {
          this.plannedTrackingDataItem = response.data;
        })
        .catch(() => (this.plannedTrackingDataItem = undefined))
        .finally(() => {
          this.isLoadingDataItem = false;
        });
    },

    didSelectOrderTrackingItem(plannedOrderItemId: string | undefined) {
      const selectedPlannedOrderItem = this.plannedTrackingDataItem?.items.find(
        (x) => x.plannedOrderTrackingDataId == plannedOrderItemId
      );

      const instruction = this.activeInstructions.find(
        (x: MaskingInstruction) => x.orderId == selectedPlannedOrderItem?.orderId
      );

      if (instruction !== undefined) {
        this.currentInstructionIndex = this.activeInstructions.indexOf(instruction);
        this.selectedPlannedOrderTrackingDataItemId = plannedOrderItemId;
      }
    }
  },
  watch: {
    activeState(newValue) {
      if (newValue != null) {
        this.plannedTrackingDataItem = undefined;
        if (this.activeState.isReady) this.loadPlannedTrackingDataItem();
      }
    },
    activeInstructions(newValue) {
      if (newValue != null) {
        if (this.currentInstructionIndex >= newValue.length) {
          this.currentInstructionIndex = 0;
        }
      }
    }
  }
});
