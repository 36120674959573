import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import axios from 'axios';
import Station from '@/models/Station';
import StationType from '@/models/StationType';
import { RouteConfig } from 'vue-router';
import { SidebarEntry, SidebarGroup } from 'rey-smartmes-ui';
import LoadingStation from '@/views/stations/LoadingStation.vue';
import UnloadingStation from '@/views/stations/UnloadingStation.vue';
import MaskingStation from '@/views/stations/MaskingStation.vue';
import FlybarPreviewStation from '@/views/stations/FlybarPreviewStation.vue';
import RobotConfigurationStation from '@/views/stations/RobotConfigurationStation.vue';
import PlanningStation from '@/views/stations/PlanningStation.vue';
import GrafanaStation from '@/views/stations/GrafanaStation.vue';
import i18n from '@/languages/i18n';

@Component({})
class StationLoader extends Vue {
  private static StationLoaderInstance: StationLoader | undefined;

  public static Instance() {
    if (!this.StationLoaderInstance) {
      this.StationLoaderInstance = new StationLoader();
    }

    return this.StationLoaderInstance;
  }
  private stations = new Array<Station>();

  private menuStations = new SidebarGroup(i18n.t('navigationGroups.stations').toString(), []);
  private menuLoadingStations: SidebarEntry = new SidebarEntry(
    i18n.t('navigationGroups.loading').toString(),
    'fas fa-arrow-up',
    '',
    []
  );
  private menuMaskingStations: SidebarEntry = new SidebarEntry(
    i18n.t('navigationGroups.masking').toString(),
    'fas fa-object-group',
    '',
    []
  );
  private menuFlybarPreviewStations: SidebarEntry = new SidebarEntry(
    i18n.t('navigationGroups.flybarPreview').toString(),
    'fab fa-buffer',
    '',
    []
  );
  private menuUnloadingStations: SidebarEntry = new SidebarEntry(
    i18n.t('navigationGroups.unloading').toString(),
    'fas fa-arrow-down',
    '',
    []
  );
  private menuRobotConfigurationStations: SidebarEntry = new SidebarEntry(
    i18n.t('navigationGroups.robotConfiguration').toString(),
    'fas fa-robot',
    '',
    []
  );
  private menuPlanningStations: SidebarEntry = new SidebarEntry(
    i18n.t('navigationGroups.planning').toString(),
    'fas fa-list',
    '',
    []
  );
  private grafanaStations: SidebarEntry = new SidebarEntry(
    i18n.t('views.reporting.dashboard.title').toString(),
    'fas fa-chart-pie',
    '',
    []
  );

  public async loadStations(): Promise<RouteConfig[]> {
    return axios
      .get('/api/Station', {
        params: {
          orderBy: 'displaySortOrder'
        }
      })
      .then((response) => {
        this.stations = response.data.entities;
        return this.getRouteConfigForStations(response.data.entities);
      })
      .catch(() => []);
  }

  public getSidebarGroup(): SidebarGroup {
    return this.menuStations;
  }

  public getStations(stationType: StationType): Station[] {
    return this.stations?.filter((x) => x.stationType === stationType) ?? Array<Station>();
  }

  private getRouteConfigForStations(stations: Station[]): RouteConfig[] {
    const routeConfigs = Array<RouteConfig>();
    stations.forEach((station) => {
      this.addStationToMenu(station);
      routeConfigs.push(this.getRouteConfigForStation(station));
    });

    if (this.menuPlanningStations.sidebarEntries.length !== 0) {
      this.menuStations.sidebarEntries.push(this.menuPlanningStations);
    }
    if (this.menuLoadingStations.sidebarEntries.length !== 0) {
      this.menuStations.sidebarEntries.push(this.menuLoadingStations);
    }
    if (this.menuMaskingStations.sidebarEntries.length !== 0) {
      this.menuStations.sidebarEntries.push(this.menuMaskingStations);
    }
    if (this.menuFlybarPreviewStations.sidebarEntries.length !== 0) {
      this.menuStations.sidebarEntries.push(this.menuFlybarPreviewStations);
    }
    if (this.menuUnloadingStations.sidebarEntries.length !== 0) {
      this.menuStations.sidebarEntries.push(this.menuUnloadingStations);
    }
    if (this.menuRobotConfigurationStations.sidebarEntries.length !== 0) {
      this.menuStations.sidebarEntries.push(this.menuRobotConfigurationStations);
    }
    if (this.grafanaStations.sidebarEntries.length !== 0) {
      this.menuStations.sidebarEntries.push(this.grafanaStations);
    }
    return routeConfigs;
  }
  private getRouteConfigForStation(station: Station): RouteConfig {
    return {
      path: StationLoader.getStationRoute(station),
      name: i18n.t(station.displayNameResourceId),
      meta: {
        breadcrumbs: ['Stationen', i18n.t(station.displayNameResourceId)],
        stationNumber: station.stationNumber,
        sidebarEntry: StationLoader.getStationMenuEntry(station)
      },
      component: StationLoader.getStationView(station)
    };
  }
  private static getStationRoute(station: Station): string {
    switch (+station.stationType) {
      case StationType.Loading:
        return '/stations/loading/' + station.stationNumber;
      case StationType.Unloading:
        return '/stations/unloading/' + station.stationNumber;
      case StationType.Masking:
        return '/stations/masking/' + station.stationNumber;
      case StationType.FlybarPreview:
        // keep 'coating' in url for backward compatibilty
        return '/stations/coating/' + station.stationNumber;
      case StationType.RoboterConfiguration:
        return '/stations/robot-configuration/' + station.stationNumber;
      case StationType.Planning:
        return '/stations/planning/' + station.stationNumber;
      case StationType.Grafana:
        return '/stations/dashboard/' + station.stationNumber;
    }
    return '';
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private static getStationView(station: Station): any {
    switch (station.stationType) {
      case StationType.Loading:
        return LoadingStation;
      case StationType.Unloading:
        return UnloadingStation;
      case StationType.Masking:
        return MaskingStation;
      case StationType.FlybarPreview:
        return FlybarPreviewStation;
      case StationType.RoboterConfiguration:
        return RobotConfigurationStation;
      case StationType.Planning:
        return PlanningStation;
      case StationType.Grafana:
        return GrafanaStation;
      default:
        return LoadingStation;
    }
  }

  private static getStationMenuEntry(station: Station): SidebarEntry {
    return new SidebarEntry(
      i18n.t(station.displayNameResourceId),
      station.icon,
      StationLoader.getStationRoute(station)
    );
  }

  private addStationToMenu(station: Station) {
    switch (station.stationType) {
      case StationType.Loading:
        this.menuLoadingStations.sidebarEntries.push(
          new SidebarEntry(
            i18n.t(station.displayNameResourceId),
            station.icon,
            StationLoader.getStationRoute(station)
          )
        );
        break;
      case StationType.Masking:
        this.menuMaskingStations.sidebarEntries.push(
          new SidebarEntry(
            i18n.t(station.displayNameResourceId),
            station.icon,
            StationLoader.getStationRoute(station)
          )
        );
        break;
      case StationType.Unloading:
        this.menuUnloadingStations.sidebarEntries.push(
          new SidebarEntry(
            i18n.t(station.displayNameResourceId),
            station.icon,
            StationLoader.getStationRoute(station)
          )
        );
        break;
      case StationType.FlybarPreview:
        this.menuFlybarPreviewStations.sidebarEntries.push(
          new SidebarEntry(
            i18n.t(station.displayNameResourceId),
            station.icon,
            StationLoader.getStationRoute(station)
          )
        );
        break;
      case StationType.RoboterConfiguration:
        this.menuRobotConfigurationStations.sidebarEntries.push(
          new SidebarEntry(
            i18n.t(station.displayNameResourceId),
            station.icon,
            StationLoader.getStationRoute(station)
          )
        );
        break;
      case StationType.Planning:
        this.menuPlanningStations.sidebarEntries.push(
          new SidebarEntry(
            i18n.t(station.displayNameResourceId),
            station.icon,
            StationLoader.getStationRoute(station)
          )
        );
        break;
      case StationType.Grafana:
        this.grafanaStations.sidebarEntries.push(
          new SidebarEntry(
            i18n.t(station.displayNameResourceId),
            station.icon,
            StationLoader.getStationRoute(station)
          )
        );
    }
  }
}

export default StationLoader;
