import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import i18n from '@/languages/i18n';
import { SystemInformation } from 'rey-smartmes-ui';
import AuthenticationService from '@/services/AuthenticationService';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/admin/colors',
      alias: '/',
      name: i18n.t('views.admin.colors.title').toString(),
      meta: {
        breadcrumbs: [
          i18n.t('navigationGroups.admin').toString(),
          i18n.t('views.admin.colors.title').toString()
        ],
        showAdminLogin: true
      },
      component: () => import('../views/admin/Colors.vue')
    },
    {
      path: '/admin/modalities',
      alias: '/',
      name: i18n.t('views.admin.modalities.title').toString(),
      meta: {
        breadcrumbs: [
          i18n.t('navigationGroups.admin').toString(),
          i18n.t('views.admin.modalities.title').toString()
        ],
        showAdminLogin: true
      },
      component: () => import('../views/admin/Modalities.vue')
    },
    {
      path: '/admin/coatings',
      name: i18n.t('views.admin.coatings.title').toString(),
      meta: {
        breadcrumbs: [
          i18n.t('navigationGroups.admin').toString(),
          i18n.t('views.admin.coatings.title').toString()
        ],
        showAdminLogin: true
      },
      component: () => import('../views/admin/Coatings.vue')
    },
    {
      path: '/admin/articles',
      name: i18n.t('views.admin.articles.title').toString(),
      meta: {
        breadcrumbs: [
          i18n.t('navigationGroups.admin').toString(),
          i18n.t('views.admin.articles.title').toString()
        ],
        showAdminLogin: true
      },
      component: () => import('../views/admin/Articles.vue')
    },
    {
      path: '/admin/orders',
      name: i18n.t('views.admin.orders.title').toString(),
      meta: {
        breadcrumbs: [
          i18n.t('navigationGroups.admin').toString(),
          i18n.t('views.admin.orders.title').toString()
        ],
        showAdminLogin: true
      },
      component: () => import('../views/admin/Orders.vue')
    },
    {
      path: '/admin/geometryColorParameter',
      name: i18n.t('views.admin.geometryColorParameter.title').toString(),
      meta: {
        breadcrumbs: [
          i18n.t('navigationGroups.admin').toString(),
          i18n.t('views.admin.geometryColorParameter.title').toString()
        ],
        showAdminLogin: true
      },
      component: () => import('../views/admin/GeometryColorParameter.vue')
    },
    {
      path: '/admin/authentications',
      name: i18n.t('views.admin.authentications.title').toString(),
      meta: {
        breadcrumbs: [
          i18n.t('navigationGroups.admin').toString(),
          i18n.t('views.admin.authentications.title').toString()
        ],
        showAdminLogin: true
      },
      component: () => import('../views/admin/Authentications.vue')
    },
    {
      path: '/reporting/zoneLogs',
      name: i18n.t('views.reporting.zoneLogs.title').toString(),
      meta: {
        breadcrumbs: [
          i18n.t('navigationGroups.reporting').toString(),
          i18n.t('views.reporting.zoneLogs.title').toString()
        ]
      },
      component: () => import('../views/reporting/ZoneLogs.vue')
    },
    {
      path: '/reporting/dashboard',
      name: i18n.t('views.reporting.dashboard.title').toString(),
      meta: {
        breadcrumbs: [
          i18n.t('navigationGroups.reporting').toString(),
          i18n.t('views.reporting.dashboard.title').toString()
        ]
      },
      component: () => import('../views/reporting/Dashboard.vue')
    },
    {
      path: '/systemadmin/systemParameters',
      name: i18n.t('views.systemadmin.systemParameters.title').toString(),
      meta: {
        breadcrumbs: [
          i18n.t('navigationGroups.systemadmin').toString(),
          i18n.t('views.systemadmin.systemParameters.title').toString()
        ]
      },
      component: () => import('../views/systemadmin/SystemParameters.vue')
    },
    {
      path: '/systemadmin/stations',
      name: i18n.t('views.systemadmin.stations.title').toString(),
      meta: {
        breadcrumbs: [
          i18n.t('navigationGroups.systemadmin').toString(),
          i18n.t('views.systemadmin.stations.title').toString()
        ]
      },
      component: () => import('../views/systemadmin/Stations.vue')
    },
    {
      path: '/systemadmin/counters',
      name: i18n.t('views.systemadmin.counters.title').toString(),
      meta: {
        breadcrumbs: [
          i18n.t('navigationGroups.systemadmin').toString(),
          i18n.t('views.systemadmin.counters.title').toString()
        ]
      },
      component: () => import('../views/systemadmin/Counters.vue')
    },
    {
      path: '/systemadmin/fielddefinitions',
      name: i18n.t('views.systemadmin.fieldDefinitions.title').toString(),
      meta: {
        breadcrumbs: [
          i18n.t('navigationGroups.systemadmin').toString(),
          i18n.t('views.systemadmin.fieldDefinitions.title').toString()
        ]
      },
      component: () => import('../views/systemadmin/FieldDefinitions.vue')
    },
    {
      path: '/systemadmin/messagetypes',
      name: i18n.t('views.systemadmin.messageTypes.title').toString(),
      meta: {
        breadcrumbs: [
          i18n.t('navigationGroups.systemadmin').toString(),
          i18n.t('views.systemadmin.messageTypes.title').toString()
        ]
      },
      component: () => import('../views/systemadmin/MessageTypes.vue')
    },
    {
      path: '/systemadmin/localizations',
      name: i18n.t('views.systemadmin.localizations.title').toString(),
      meta: {
        breadcrumbs: [
          i18n.t('navigationGroups.systemadmin').toString(),
          i18n.t('views.systemadmin.localizations.title').toString()
        ]
      },
      component: () => import('../views/systemadmin/Localizations.vue')
    },
    {
      path: '/systeminformation',
      name: i18n.t('general.systeminformation').toString(),
      meta: {
        breadcrumbs: [i18n.t('general.systeminformation').toString()],
        requireAuth: true
      },
      component: SystemInformation
    }
  ]
});
router.beforeEach((to, from, next) => {
  AuthenticationService.logout();
  next();
});
export default () => router;
