














import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import i18n, { activateLanguage, getAvailableLanguages } from '@/languages/i18n';

@Component({
  components: {}
})
export default class LanguageSelector extends Vue {
  public getAvailableLanguages = getAvailableLanguages;
  public activateLanguage = activateLanguage;

  public isActiveLanguage(culture: string): boolean {
    return i18n.locale == culture;
  }
}
