












import { Rotation } from '@/models/Rotation';
import { Vue } from 'vue-property-decorator';
import {
  PlannedOrderTrackingDataState,
  plannedOrderTrackingDataStateIcon
} from '@/models/PlannedOrderTrackingDataState';

export default Vue.extend({
  name: 'PlannedOrderTrackingDataItemVisualization',
  props: ['item', 'pxPerMilli', 'isActive', 'index'],
  methods: {
    mmToPx(valueInPx: number): number {
      return Math.floor(valueInPx * this.pxPerMilli);
    }
  },
  computed: {
    rotationClass(): string {
      switch (this.item.rotation) {
        case Rotation.NoRotation0:
          return 'norotation-0';
        case Rotation.Clockwise90:
          return 'clockwise-90';
        case Rotation.Clockwise270:
          return 'clockwise-270';
        case Rotation.UpsideDown180:
          return 'upside-down-180';
      }
      return '';
    },
    stateClass(): string {
      if (!this.item.orderIsReady) {
        return 'state-notready';
      }
      switch (this.item.plannedOrderTrackingDataState) {
        case PlannedOrderTrackingDataState.Planned:
          return 'state-planned';
        case PlannedOrderTrackingDataState.Scanned:
          return 'state-scanned';
        case PlannedOrderTrackingDataState.FlybarStarted:
          return 'state-flybarstarted';
        case PlannedOrderTrackingDataState.Cancelled:
          return 'state-cancelled';
      }
      return '';
    },
    isSideways(): boolean {
      return (
        this.item.rotation === Rotation.Clockwise90 || this.item.rotation === Rotation.Clockwise270
      );
    },
    height(): number {
      return this.isSideways ? this.item.width : this.item.height;
    },
    width(): number {
      return this.isSideways ? this.item.height : this.item.width;
    },
    icon() {
      if (!this.item.orderIsReady) {
        return 'fas fa-times-circle';
      }
      return plannedOrderTrackingDataStateIcon(this.item.plannedOrderTrackingDataState);
    }
  }
});
