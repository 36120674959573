






















































































import { Vue } from 'vue-property-decorator';
import {
  PlannedOrderTrackingDataState,
  plannedOrderTrackingDataStateIcon
} from '@/models/PlannedOrderTrackingDataState';
import PlannedOrderTrackingDataItem from '@/models/PlannedOrderTrackingDataItem';
import LoadingInstruction from '@/models/LoadingInstruction';
import ArticleParameter from '@/models/ArticleParameter';

export default Vue.extend({
  name: 'PlannedTrackingDataDetails',
  props: [
    'item',
    'isActive',
    'isScanning',
    'selectedPlannedOrderTrackingDataId',
    'activeInstructions',
    'showActions'
  ],
  data: () => {
    return {
      selectedAdditionalOrderNumber: undefined as string | undefined
    };
  },
  methods: {
    selectItem(item: PlannedOrderTrackingDataItem) {
      if (this.selectedPlannedOrderTrackingDataId === item.plannedOrderTrackingDataId) {
        this.$emit('didSelectPlannedOrderTrackingDataItem', undefined);
      } else {
        this.$emit('didSelectPlannedOrderTrackingDataItem', item.plannedOrderTrackingDataId);
      }
    },
    icon(item: PlannedOrderTrackingDataItem) {
      if (!item.orderIsReady) {
        return 'fas fa-times-circle';
      }
      return plannedOrderTrackingDataStateIcon(item.plannedOrderTrackingDataState);
    },
    stateClass(item: PlannedOrderTrackingDataItem): string {
      if (!item.orderIsReady) {
        return 'state-notready';
      }
      switch (item.plannedOrderTrackingDataState) {
        case PlannedOrderTrackingDataState.Planned:
          return 'state-planned';
        case PlannedOrderTrackingDataState.Scanned:
          return 'state-scanned';
        case PlannedOrderTrackingDataState.FlybarStarted:
          return 'state-flybarstarted';
        case PlannedOrderTrackingDataState.Cancelled:
          return 'state-cancelled';
      }
      return '';
    },

    async cancelOrderItem() {
      if (!this.isCancelButtonActive) {
        return;
      }

      if (this.selectedAdditionalOrderNumber) {
        this.$emit('cancelOrderNumber', this.selectedAdditionalOrderNumber);
      } else if (this.selectedItem) {
        this.$emit('cancelOrderNumber', this.selectedItem?.orderNumber);
      }
    },

    removeOrderItem() {
      if (!this.isRemoveButtonActive) {
        return;
      }

      if (this.selectedAdditionalOrderNumber) {
        this.$emit('removeOrderNumber', this.selectedAdditionalOrderNumber);
      } else if (this.selectedItem) {
        this.$emit('removeOrderNumber', this.selectedItem?.orderNumber);
      }
    },

    extractParameter(
      orderItem: PlannedOrderTrackingDataItem,
      parameter: string
    ): string | undefined {
      const trayParameter = orderItem.articleParameters?.find(
        (x: ArticleParameter) => x.parameter == parameter
      );

      if (trayParameter != null && trayParameter.value != undefined) {
        return trayParameter.value as string;
      }
    }
  },
  computed: {
    additionalItems() {
      // show orders on current (active!) flybar which have not been planned for this flybar.
      return this.activeInstructions.filter(
        (x: LoadingInstruction) =>
          this.item.items.find(
            (y: PlannedOrderTrackingDataItem) => y.orderNumber == x.orderNumber
          ) === undefined
      );
    },
    buttonsEnabled() {
      return (
        (this.selectedPlannedOrderTrackingDataId || this.selectedAdditionalOrderNumber) &&
        !this.isScanning
      );
    },
    isCancelButtonActive(): boolean {
      return (
        (this.buttonsEnabled && this.selectedAdditionalOrderNumber) ||
        (this.selectedItem != undefined &&
          this.selectedItem.plannedOrderTrackingDataState === PlannedOrderTrackingDataState.Planned)
      );
    },
    isRemoveButtonActive(): boolean {
      return (
        ((this.buttonsEnabled && this.selectedAdditionalOrderNumber) ||
          (this.selectedItem != undefined &&
            this.selectedItem.plannedOrderTrackingDataState ===
              PlannedOrderTrackingDataState.Scanned)) &&
        this.item.items
          .slice()
          .reverse()
          .find(
            (x: PlannedOrderTrackingDataItem) =>
              x.plannedOrderTrackingDataState == PlannedOrderTrackingDataState.Scanned
          )?.orderId === this.selectedItem?.orderId
      );
    },
    selectedItem(): PlannedOrderTrackingDataItem | undefined {
      return this.item.items.find(
        (x: PlannedOrderTrackingDataItem) =>
          x.plannedOrderTrackingDataId == this.selectedPlannedOrderTrackingDataId
      );
    }
  },
  watch: {
    selectedAdditionalOrderNumber(newValue) {
      if (newValue) {
        this.$emit('didSelectPlannedOrderTrackingDataItem', undefined);
      }
    },
    selectedPlannedOrderTrackingDataId(newValue) {
      if (newValue) {
        this.selectedAdditionalOrderNumber = undefined;
      }
    }
  }
});
