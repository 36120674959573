






































import PlannedTrackingDataItem from '@/models/PlannedTrackingDataItem';
import { Vue } from 'vue-property-decorator';
import { PlannedOrderTrackingDataState } from '@/models/PlannedOrderTrackingDataState';
import PlannedOrderTrackingDataItem from '@/models/PlannedOrderTrackingDataItem';

export default Vue.extend({
  name: 'ListPlannedTrackingData',
  props: ['plannedTrackingData', 'activeOrderId', 'selectedPlannedTrackingDataId'],
  data: () => {
    return {};
  },

  methods: {
    didSelectItem(item: PlannedTrackingDataItem) {
      if (this.selectedPlannedTrackingDataId == item.plannedTrackingDataId) {
        this.$emit('didSelectPlannedTrackingDataItem', undefined);
      } else {
        this.$emit('didSelectPlannedTrackingDataItem', item.plannedTrackingDataId);
      }
    },
    isActiveItem(item: PlannedTrackingDataItem): boolean {
      if (this.activeOrderId == undefined || !item || !item.items) {
        return false;
      }
      return (
        item.items.find((x: PlannedOrderTrackingDataItem) => x.orderId == this.activeOrderId) !==
        undefined
      );
    },
    warnIconsForItem(item: PlannedTrackingDataItem): string[] {
      return Array.from(new Set(item.items.flatMap((x) => x.articleWarnHintIcons)));
    },
    activeItemsCount(item: PlannedTrackingDataItem) {
      return item.items.filter(
        (x) => x.plannedOrderTrackingDataState == PlannedOrderTrackingDataState.Planned
      ).length;
    }
  }
});
