



























































































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import FlybarPreviewStationState from '@/models/FlybarPreviewStation/FlybarPreviewStationState';
import axios from 'axios';
import StationType from '@/models/StationType';
import StationParameter from '@/models/StationParameter';
import Flybar from '@/models/FlybarPreviewStation/Flybar';
import UiControlType from '@/models/UiControlType';

@Component({
  components: {}
})
export default class FlybarPreviewStation extends Vue {
  // Data properties
  public activeState: FlybarPreviewStationState = {
    stationNumber: 0,
    currentColorCount: 0,
    currentColorCode: '',
    currentColorHex: '#FFFFFF',
    nextColorCount: 0,
    nextColorCode: '',
    nextColorHex: '#FFFFFF',
    bufferZones: []
  };
  public isInitialized = false;
  public hideCurrentAndNextColor = false;
  public iconsUiControlType = UiControlType.Icons;

  // Lifecycle hooks
  public async mounted(): Promise<void> {
    // @ts-ignore
    this.$messagesService.$on('CurrentFlybarPreviewStationState', this.updateStationState);
    await this.loadStationParameters();
  }

  public async unmounted(): Promise<void> {
    // @ts-ignore
    this.$messagesService.$off('CurrentFlybarPreviewStationState', this.updateStationState);
  }

  // computed values
  get currentStationNumber(): number {
    // @ts-ignore
    return this.$route.meta.stationNumber;
  }

  // private methods
  private updateStationState(currentState: FlybarPreviewStationState) {
    // @ts-ignore
    if (this.currentStationNumber !== currentState.stationNumber) {
      return;
    }
    this.activeState = currentState;
    this.isInitialized = true;
  }

  private fieldDefinitionValueResolver(flybar: Flybar, fieldDefinitionId: string): string[] {
    let values =
      flybar.fieldDefinitionValues.find((fdv) => fdv.fieldDefinitionId === fieldDefinitionId)
        ?.values ?? [];
    return values.filter((v) => v != null && v != undefined).map((v) => v.toString());
  }

  public fieldDefinitionValue(flybar: Flybar, fieldDefinitionId: string): string {
    let values = this.fieldDefinitionValueResolver(flybar, fieldDefinitionId);
    return values.join(',');
  }

  public fieldDefinitionIconsValue(flybar: Flybar, fieldDefinitionId: string): string {
    let iconJson = this.fieldDefinitionValueResolver(flybar, fieldDefinitionId);
    let iconArray = iconJson.flatMap((json) => JSON.parse(json as string));
    let distinctIcons = [...new Set(iconArray)];
    return distinctIcons.map((icon) => `<em class="${icon}" />`).join();
  }

  private async loadStationParameters() {
    await axios
      .post('/api/actions/GetStationParameters/', {
        StationNumber: this.currentStationNumber,
        StationType: StationType.FlybarPreview
      })
      .then((response) => response.data)
      .then((result) => this.updateStationParameters(result));
  }

  private updateStationParameters(parameters: StationParameter[]) {
    if (parameters) {
      let hideCurrentAndNextColor = parameters.filter(
        (x) => x.parameterDefinitionId === 'HideCurrentAndNextColor'
      );
      // eslint-disable-next-line
      if (hideCurrentAndNextColor.some((_) => true)) {
        this.hideCurrentAndNextColor = hideCurrentAndNextColor[0].value === 'True';
      }
    }
  }
}
