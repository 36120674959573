import i18n from '@/languages/i18n';

export enum AuthenticationType {
  TeamLead = 0,
  Admin = 1
}

export const AuthenticationTypeDropdown = () => [
  {
    id: AuthenticationType.TeamLead,
    title: i18n.t('dropdowns.authenticationTypes.teamLead').toString()
  },
  {
    id: AuthenticationType.Admin,
    title: i18n.t('dropdowns.authenticationTypes.admin').toString()
  }
];

export default AuthenticationType;
