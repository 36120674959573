








































import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import axios from 'axios';
import StationType from '@/models/StationType';
import StationParameter from '@/models/StationParameter';
import DefaultMaskingView from '@/components/MaskingViews/DefaultMaskingView.vue';
import PlannedFlybarMaskingView from '@/components/MaskingViews/PlannedFlybarMaskingView.vue';
// @ts-ignore
import { Alert } from 'rey-vue-common';
import MaskingStationState from '@/models/MaskingStationState';
import MaskingInstruction from '@/models/MaskingInstruction';

@Component({
  components: { PlannedFlybarMaskingView, DefaultMaskingView, Alert }
})
export default class MaskingStation extends Vue {
  public maskingViewType = -1;
  public isLoading = true;
  public activeState: MaskingStationState = {
    stationNumber: 0,
    isReady: false
  } as MaskingStationState;
  public stationParameters = [] as StationParameter[];
  public activeInstructions = [] as MaskingInstruction[];

  public async mounted(): Promise<void> {
    await this.loadStationParameters();
    // @ts-ignore
    this.$messagesService.$on('CurrentMaskingStationState', this.updateStationState);
  }
  public async unmounted(): Promise<void> {
    // @ts-ignore
    this.$messagesService.$off('CurrentMaskingStationState', this.updateStationState);
  }

  private async updateStationState(currentState: MaskingStationState) {
    if (
      // @ts-ignore
      this.$route.meta.stationNumber !== currentState.stationNumber ||
      JSON.stringify(this.activeState) === JSON.stringify(currentState)
    ) {
      return;
    }
    this.activeState = currentState;
  }
  private async loadMaskingInstruction(state: MaskingStationState) {
    await axios
      .post('/api/actions/GetMaskingInstruction/', {
        TrackingNumber: state.trackingNumber,
        CycleNumber: state.cycleNumber,
        StationNumber: this.currentStationNumber
      })
      .then((response) => {
        this.activeInstructions = response.data;
      })
      .catch(() => (this.activeInstructions = [] as MaskingInstruction[]));
  }

  private async loadStationParameters() {
    await axios
      .post('/api/actions/GetStationParameters/', {
        StationNumber: this.currentStationNumber,
        StationType: StationType.Masking
      })
      .then((response) => (this.stationParameters = response.data))
      .then((result) => this.loadStationViewType(result))
      .finally(() => (this.isLoading = false));
  }

  private loadStationViewType(parameters: StationParameter[]) {
    let values = parameters.filter((x) => x.parameterDefinitionId === 'MaskingStationTemplate');
    // eslint-disable-next-line
    if (values.some((_) => true)) {
      this.maskingViewType = parseInt(values[0].value);
    }
  }

  get currentStationNumber(): number {
    // @ts-ignore
    return this.$route.meta.stationNumber;
  }

  @Watch('activeState')
  private onActiveStateChanged() {
    if (this.activeState.trackingNumber === 0) {
      this.activeInstructions = [] as MaskingInstruction[];
      return;
    }
    this.loadMaskingInstruction(this.activeState);
  }
}
