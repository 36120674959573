








































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import axios from 'axios';
// @ts-ignore
import { Alert } from 'rey-vue-common';

declare interface GetCompartmentTrolleysOrderOutput {
  orderId: number;
  orderNumber: string | undefined;
  wbtOrderNumber: string | undefined;
}
declare interface GetCompartmentTrolleysOutput {
  compartmentTrolleyNumber: number;
  minSortOrder: number;
  orders: GetCompartmentTrolleysOrderOutput[];
}

@Component({
  components: { Alert }
})
export default class WBTCompartmentTrolleyOverview extends Vue {
  public pollingInterval = 5000;
  public isLoading = false;
  public isMounted = false;
  public showErrorMessage = false;
  public updateDate = new Date(0);
  public trolleys = [] as GetCompartmentTrolleysOutput[];

  public async mounted(): Promise<void> {
    this.isMounted = true;
    await this.reload();
  }

  public async unmounted(): Promise<void> {
    this.isMounted = false;
  }

  public async reload(): Promise<void> {
    this.isLoading = true;
    try {
      const result = await axios.get<GetCompartmentTrolleysOutput[]>(
        '/api/actions/GetCompartmentTrolleys'
      );
      this.trolleys = result.data;
      this.showErrorMessage = false;
    } catch {
      this.showErrorMessage = true;
    } finally {
      this.updateDate = new Date();
      this.isLoading = false;
      if (this.isMounted) {
        setTimeout(async () => {
          await this.reload();
        }, this.pollingInterval);
      }
    }
  }
}
