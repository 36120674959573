








































































import Vue from 'vue';
// @ts-ignore
import VueBarcode from 'vue-barcode';
import AdditionalParameterValue from '../../../views/stations/AdditionalParameterValue.vue';

export default Vue.extend({
  name: 'DefaultMaskingView',
  components: { barcode: VueBarcode, AdditionalParameterValue },
  props: ['activeInstructions', 'currentInstructionIndex', 'showBarcode']
});
