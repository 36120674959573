






















































































































































































































































































































































































































































import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import axios from 'axios';
import Order from '@/models/Order';
import LoadingInstruction from '@/models/LoadingInstruction';
import LoadingStationState from '@/models/LoadingStationState';
import ProcessBarcodeResponseState from '@/models/ProcessBarcodeResponseState';
import InstructionViewer from '@/components/InstructionViewer.vue';
// @ts-ignore
import VueBarcode from 'vue-barcode';
import StationParameter from '@/models/StationParameter';
import StationType from '@/models/StationType';
import BarcodeInterpretationMode from '@/models/BarcodeInterpretationMode';
// @ts-ignore
import { Alert } from 'rey-vue-common';
import AdditionalParameterValue from '@/views/stations/AdditionalParameterValue.vue';
import FlybarCountValue from '@/views/stations/FlybarCountValue.vue';
import { ProcessState } from '@/models/ProcessState';
import { ScanState } from '@/models/ScanState';
import { ScanMode } from '@/models/ScanMode';
import TodaysOrders from '@/components/TodaysOrders.vue';
import CurrentFlybar from '@/components/CurrentFlybar.vue';
import InstructionDisplayMode from '@/models/InstructionDisplayMode';
import PlannedUpcomingFlybars from '@/components/PlannedUpcomingFlybars.vue';
import PlannedFlybarVisualization from '@/components/PlannedFlybarVisualization.vue';
import PlannedTrackingDataItem from '@/models/PlannedTrackingDataItem';
import PlannedOrderTrackingDataItem from '@/models/PlannedOrderTrackingDataItem';
import { GetPlannedTrackingDataResponse } from '@/models/GetPlannedTrackingDataResponse';
import Pagination from '@/components/Pagination.vue';
import systemParameterService from '@/services/SystemParameterService';
import { AuthenticationType, AuthenticationTypeDropdown } from '@/models/AuthenticationType';
import EnterPasswordDialog from '@/components/EnterPasswordDialog.vue';

@Component({
  components: {
    EnterPasswordDialog,
    PlannedFlybarVisualization,
    PlannedUpcomingFlybars,
    CurrentFlybar,
    TodaysOrders,
    FlybarCountValue,
    AdditionalParameterValue,
    barcode: VueBarcode,
    InstructionViewer,
    Alert,
    Pagination
  }
})
export default class LoadingStation extends Vue {
  public ProcessState = ProcessState;
  public ScanState = ScanState;
  public ScanMode = ScanMode;
  public AuthenticationType = AuthenticationType;
  public AuthenticationTypeDropdown = AuthenticationTypeDropdown;
  public InstructionDisplayMode = InstructionDisplayMode;
  public instructionDisplayMode: InstructionDisplayMode = InstructionDisplayMode.TextAndImages;
  public barcodeInput = '';
  public articleCount = undefined as number | undefined;
  public lastBarcodeInput = '';
  public processBarcodeState = ProcessState.NotStarted;
  public scanState = -1;
  public responseMessage = '';
  public todaysOrders: Order[] = [] as Order[];
  public activeOrder: Order = { id: -1 } as Order;
  public activeInstructions: LoadingInstruction[] = [] as LoadingInstruction[];
  public plannedInstructions: LoadingInstruction[] = [] as LoadingInstruction[];
  public isReady = false;
  public currentInstructionIndex = 0;
  public flybarNumber = 0;
  public BarcodeInterpretationMode = BarcodeInterpretationMode;
  public usedBarcodeInterpretationMode: BarcodeInterpretationMode =
    BarcodeInterpretationMode.Default;
  public enableCountOnFlybar = false;
  public isScanning = false;
  public showButtonsAsBarcodes = true;
  public showArticleBarcode = true;
  public showActions = false;
  public scanMode: ScanMode = ScanMode.Default;
  public successReset: undefined | number;
  public updateCurrentIndexOnNextUpdate = false;
  public showsCurrentFlybar = true;
  public showPlannedTrackingData = false;
  public showTodaysOrders = true;
  public stationParameters: StationParameter[] = [];
  public plannedTrackingData: PlannedTrackingDataItem[] = [];
  public selectedPlannedTrackingDataId = '';
  public selectedPlannedOrderTrackingDataId: string | undefined = '';
  public trackingNumber = 0;
  public cancelPrefix = 'c:';

  public currentSkipped = 0;
  public totalCount = 0;
  public skipped = 0;
  public taken = 0;
  public pageSize = 25;
  public filterIsReady = true;
  public filterIsReadyTodaysOrders = true;

  public async toggleFilterIsReady(): Promise<void> {
    this.filterIsReady = !this.filterIsReady;
    await this.loadUpcomingFlybars();
  }

  public async handlePagination(skip: number): Promise<void> {
    this.currentSkipped = skip;
    await this.loadUpcomingFlybars();
  }

  public created(): void {
    window.addEventListener('keydown', this.handleKeyDown);
  }

  public beforeDestroyed(): void {
    window.removeEventListener('keydown', this.handleKeyDown);
  }

  public async mounted(): Promise<void> {
    // @ts-ignore
    this.$messagesService.$on('CurrentLoadingStationState', this.updateStationState);
    // @ts-ignore
    this.$messagesService.$on('ProcessBarcodeResponseState', this.handleProcessBarcodeResponse);
    await this.loadStationParameters();
    if (this.usedBarcodeInterpretationMode == BarcodeInterpretationMode.Order) {
      this.loadTodaysOrders();
    }
    await this.loadUpcomingFlybars();
  }

  public async unmounted(): Promise<void> {
    // @ts-ignore
    this.$messagesService.$off('CurrentLoadingStationState', this.updateStationState);
    // @ts-ignore
    this.$messagesService.$off('ProcessBarcodeResponseState', this.handleProcessBarcodeResponse);
  }

  public beforeUpdate(): void {
    this.onScanModeChange(this.scanMode);
  }

  private async handleProcessBarcodeResponse(responseState: ProcessBarcodeResponseState) {
    if (responseState.stationNumber != this.currentStationNumber) {
      return;
    }

    this.updateCurrentIndexOnNextUpdate = true;

    await this.onProcessBarcodeFinished(
      responseState.isError ? ProcessState.Failed : ProcessState.Succeeded,
      responseState.scanState,
      responseState.trackingNumber
    );

    await this.loadUpcomingFlybars();
    if (responseState.trackingNumber) {
      this.trackingNumber = responseState.trackingNumber;
      await this.updateInstructions();
    }
  }

  private async updateStationState(currentState: LoadingStationState) {
    if (this.currentStationNumber !== currentState.stationNumber) {
      return;
    }

    await this.loadUpcomingFlybars();

    if (!currentState.isReady) {
      this.activeOrder = { id: -1 } as Order;
      this.activeInstructions = [] as LoadingInstruction[];
      this.plannedInstructions = [] as LoadingInstruction[];
      this.scanState = -1;
      this.responseMessage = '';
    }
    this.isReady = currentState.isReady;
    this.flybarNumber = currentState.flybarNumber;
    this.trackingNumber = currentState.trackingNumber;
    await this.updateInstructions();

    this.$nextTick(() => {
      (this.$refs.barcode as HTMLInputElement)?.focus();
    });
  }

  private async updateInstructions() {
    if (!this.isReady) {
      return;
    }

    await this.loadInstruction(this.trackingNumber, this.selectedPlannedTrackingDataId);
  }

  private async loadTodaysOrders() {
    await axios
      .post('/api/actions/GetTodaysOrders/', {
        stationNumber: this.currentStationNumber,
        filterIsReady: this.filterIsReadyTodaysOrders
      })
      .then((response) => {
        this.todaysOrders = response.data;
      })
      .catch(() => (this.todaysOrders = []));
  }

  public didUpdateTodaysOrderIsReadyFilter(isReadyFilter: boolean) {
    this.filterIsReadyTodaysOrders = isReadyFilter;
    this.loadTodaysOrders();
  }

  private async validatePasswordRequirements(password: string | undefined): Promise<boolean> {
    if (!this.barcodeInput.startsWith(this.cancelPrefix)) {
      // action is not cancelling. Not requiring any passwords.
      return true;
    }

    const usingPasswords = await systemParameterService.getSystemParameterBool('UsePasswords');
    const stationRequiresPassword = this.findBooleanValueForStationParameter(
      this.stationParameters,
      'RequirePasswordForCancellingOrders'
    );
    const requiresPassword = usingPasswords && stationRequiresPassword;

    if (requiresPassword) {
      if (!password) {
        return false;
      }

      return new Promise((resolve, reject) => {
        axios
          .post('/api/Authentications/validate', {
            password: password,
            authenticationType: AuthenticationType.TeamLead
          })
          .then((_) => resolve(true))
          .catch((_) => resolve(false));
      });
    }
    return true;
  }

  public async processBarcode(password: string | undefined = undefined): Promise<void> {
    if (!(await this.validatePasswordRequirements(password))) {
      // @ts-ignore
      this.$refs.enterPasswordDialog.show(password !== undefined);
      return;
    }
    this.processBarcodeState = ProcessState.Started;
    this.scanState = -1;
    this.lastBarcodeInput = '';
    this.isScanning = true;

    await axios
      .post('/api/actions/ProcessBarcode', {
        Barcode: this.barcodeInput,
        WorkstationNumber: this.currentStationNumber,
        Count: this.articleCount
      })
      .finally(() => (this.isScanning = false));
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private async onProcessBarcodeFinished(
    processState: ProcessState,
    scanState: ScanState,
    trackingNumber?: number
  ) {
    if (this.successReset) {
      clearTimeout(this.successReset);
      this.resetSuccessMessage();
      // required to show animation of success label again (when it's already shown)
      setTimeout(() => this.onProcessBarcodeFinished(processState, scanState, trackingNumber), 1);
      return;
    }

    this.articleCount = undefined;
    this.scanState = scanState;
    const tmpOrder = this.todaysOrders.find((x) => x.barcode === this.barcodeInput);
    this.activeOrder = tmpOrder === undefined ? ({ id: -1 } as Order) : tmpOrder;

    if (!this.barcodeInput.startsWith('r:')) {
      this.lastBarcodeInput = this.barcodeInput;
    }
    if (processState === ProcessState.Succeeded) {
      this.currentInstructionIndex = this.getMostRelevantInstructionIndex();
      const currentOrderId = this.activeInstructions[this.currentInstructionIndex]?.orderId;
      const currentPlannedTrackingDataItem = this.selectedPlannedTrackingData?.items.find(
        (x) => x.orderId === currentOrderId
      );
      this.selectedPlannedOrderTrackingDataId =
        currentPlannedTrackingDataItem?.plannedOrderTrackingDataId;
    }

    if (trackingNumber) {
      this.trackingNumber = trackingNumber;
      await this.updateInstructions();
    }

    if (this.usedBarcodeInterpretationMode == BarcodeInterpretationMode.Order) {
      await this.loadTodaysOrders();
    }

    this.processBarcodeState = processState;
    this.barcodeInput = '';

    this.$nextTick(() => {
      (this.$refs.barcode as HTMLInputElement)?.focus();
    });

    this.successReset = setTimeout(() => {
      this.resetSuccessMessage();
    }, 5000);
  }

  private resetSuccessMessage() {
    this.scanState = -1;
    this.successReset = undefined;
  }

  private async loadInstruction(trackingNumber: number, plannedTrackingDataId: string) {
    try {
      this.activeInstructions =
        !trackingNumber || trackingNumber === 0
          ? []
          : (
              await axios.post('/api/actions/GetLoadingInstruction/', {
                TrackingNumber: trackingNumber,
                StationNumber: this.currentStationNumber
              })
            ).data;
      this.plannedInstructions =
        this.instructionDisplayMode === InstructionDisplayMode.VisualizationOfPlannedFlybar
          ? (
              await axios.post('/api/actions/GetPlannedLoadingInstruction/', {
                PlannedTrackingDataId: plannedTrackingDataId,
                StationNumber: this.currentStationNumber
              })
            ).data
          : [];

      if (this.isReady && this.updateCurrentIndexOnNextUpdate) {
        this.currentInstructionIndex = this.getMostRelevantInstructionIndex();
      }
    } catch {
      this.activeInstructions = [] as LoadingInstruction[];
      this.plannedInstructions = [] as LoadingInstruction[];
    }
  }

  @Watch('scanMode')
  private onScanModeChange(newValue: ScanMode) {
    if (newValue == ScanMode.FromUserInterface && this.$refs.barcode) {
      (this.$refs.barcode as HTMLInputElement).focus();
    }
  }

  private getMostRelevantInstructionIndex() {
    this.updateCurrentIndexOnNextUpdate = false;

    let relevantIndex = this.getInstructionIndexFromLastScannedBarcode();

    if (relevantIndex !== -1) {
      return relevantIndex;
    }

    relevantIndex = this.getInstructionIndexFromSelectedPlannedOrderItem();

    if (relevantIndex !== -1) {
      return relevantIndex;
    }
    // otherwise display the last instruction of the list
    return this.allInstructions.length > 0 ? this.allInstructions.length - 1 : 0;
  }

  private getInstructionIndexFromLastScannedBarcode(): number {
    return this.usedBarcodeInterpretationMode === BarcodeInterpretationMode.Article
      ? this.allInstructions.findIndex((x) => x.articleNumber === this.lastBarcodeInput)
      : this.allInstructions.findIndex((x) => x.orderNumber === this.lastBarcodeInput);
  }

  private getInstructionIndexFromSelectedPlannedOrderItem(): number {
    if (!this.selectedPlannedOrderTrackingDataItem) {
      return -1;
    }

    return this.allInstructions.findIndex(
      (x) => x.orderId === this.selectedPlannedOrderTrackingDataItem?.orderId
    );
  }

  private handleKeyDown(e: KeyboardEvent) {
    if (e.key === 'ArrowUp') {
      e.preventDefault();
      this.setCurrentIndexOffset(1);
    } else if (e.key === 'ArrowDown') {
      e.preventDefault();
      this.setCurrentIndexOffset(-1);
    }
  }

  public setCurrentIndexOffset(offset: number): void {
    this.currentInstructionIndex =
      (this.currentInstructionIndex + offset + this.allInstructions.length) %
      this.allInstructions.length;
  }

  private async loadStationParameters() {
    await axios
      .post('/api/actions/GetStationParameters/', {
        StationNumber: this.currentStationNumber,
        StationType: StationType.Loading
      })
      .then((response) => response.data)
      .then((result) => this.updateStationParameters(result));
  }

  private updateStationParameters(parameters: StationParameter[]) {
    if (parameters) {
      this.stationParameters = parameters;
      this.usedBarcodeInterpretationMode = this.findIntValueForStationParameter(
        parameters,
        'BarcodeInterpretationMode'
      );
      this.scanMode = this.findIntValueForStationParameter(parameters, 'ScanMode');
      this.instructionDisplayMode = this.findIntValueForStationParameter(
        parameters,
        'LoadingInstructionDisplayMode',
        1
      );
      this.enableCountOnFlybar = this.findBooleanValueForStationParameter(
        parameters,
        'EnableCountOnFlybar'
      );
      this.showButtonsAsBarcodes = this.findBooleanValueForStationParameter(
        parameters,
        'ShowButtonsAsBarcodes'
      );
      this.showArticleBarcode = this.findBooleanValueForStationParameter(
        parameters,
        'ShowArticleBarcode'
      );
      this.showActions = this.findBooleanValueForStationParameter(parameters, 'ShowActions');
      this.showsCurrentFlybar = this.findBooleanValueForStationParameter(
        parameters,
        'ShowCurrentFlybarContents'
      );
      this.showPlannedTrackingData = this.findBooleanValueForStationParameter(
        parameters,
        'ShowPlannedTrackingData'
      );
      this.showTodaysOrders = this.findBooleanValueForStationParameter(
        parameters,
        'ShowTodaysOrders'
      );

      this.loadUpcomingFlybars();
    }
  }

  private findStringValueForStationParameter(
    parameters: StationParameter[],
    fieldDefinitionId: string
  ): string {
    const values = parameters.filter((x) => x.parameterDefinitionId === fieldDefinitionId);
    // eslint-disable-next-line
    if (values.some((_) => true)) {
      return values[0].value;
    }
    return '';
  }

  private findBooleanValueForStationParameter(
    parameters: StationParameter[],
    fieldDefinitionId: string
  ): boolean {
    const values = parameters.filter((x) => x.parameterDefinitionId === fieldDefinitionId);
    // eslint-disable-next-line
    if (values.some((_) => true)) {
      return values[0].value === 'True';
    }
    return false;
  }

  private findIntValueForStationParameter(
    parameters: StationParameter[],
    fieldDefinitionId: string,
    defaultValue = 0
  ): number {
    let values = parameters.filter((x) => x.parameterDefinitionId === fieldDefinitionId);
    // eslint-disable-next-line
    if (values.some((_) => true)) {
      return parseInt(values[0].value);
    }
    return defaultValue;
  }

  async loadUpcomingFlybars(): Promise<void> {
    if (
      this.showPlannedTrackingData ||
      this.instructionDisplayMode == InstructionDisplayMode.VisualizationOfPlannedFlybar
    ) {
      await axios
        .post('/api/actions/GetPlannedTrackingData/', {
          stationNumber: this.currentStationNumber,
          skip: this.currentSkipped,
          take: this.pageSize,
          filterIsReady: this.filterIsReady
        })
        .then((response: GetPlannedTrackingDataResponse) => {
          this.plannedTrackingData = response.data.plannedTrackingData;
          this.skipped = response.data.skipped;
          this.totalCount = response.data.totalCount;
          this.taken = response.data.taken;

          if (!this.selectedPlannedTrackingData && this.plannedTrackingData.length > 0) {
            this.selectedPlannedTrackingDataId = this.plannedTrackingData[0].plannedTrackingDataId;
          }
        })
        .catch(() => (this.plannedTrackingData = []));
    }
  }

  get currentStationNumber(): number {
    // @ts-ignore
    return this.$route?.meta?.stationNumber;
  }

  get activeOrderId(): number | undefined {
    if (!this.activeInstructions || this.activeInstructions.length == 0) {
      return undefined;
    }

    return this.activeInstructions[0].orderId;
  }

  get selectedPlannedTrackingData(): PlannedTrackingDataItem | undefined {
    if (!this.selectedPlannedTrackingDataId || this.selectedPlannedTrackingDataId == '') {
      return undefined;
    }

    return this.plannedTrackingData.find(
      (x) => x.plannedTrackingDataId == this.selectedPlannedTrackingDataId
    );
  }

  get selectedPlannedOrderTrackingDataItem(): PlannedOrderTrackingDataItem | undefined {
    const selectedTrackingData = this.selectedPlannedTrackingData;
    if (!selectedTrackingData) {
      return undefined;
    }

    if (!this.selectedPlannedOrderTrackingDataId || this.selectedPlannedOrderTrackingDataId == '') {
      return undefined;
    }

    return selectedTrackingData.items.find(
      (x: PlannedOrderTrackingDataItem) =>
        x.plannedOrderTrackingDataId == this.selectedPlannedOrderTrackingDataId
    );
  }

  public didSetCountOnFlybar(count: number): void {
    this.barcodeInput =
      this.usedBarcodeInterpretationMode === BarcodeInterpretationMode.Article
        ? this.activeInstructions[this.currentInstructionIndex]?.articleNumber
        : this.activeInstructions[this.currentInstructionIndex]?.orderNumber;
    this.articleCount = count;
    this.processBarcode();
  }

  public async selectedPlannedTrackingDataChanged(plannedTrackingDataId: string): Promise<void> {
    if (this.selectedPlannedTrackingDataId === plannedTrackingDataId) {
      return;
    }
    this.selectedPlannedTrackingDataId = plannedTrackingDataId;
    this.activeInstructions = [];
    this.plannedInstructions = [];
    await this.updateInstructions();
  }

  public selectedPlannedOrderTrackingDataItemChanged(
    plannedOrderTrackingItemId: string | undefined
  ): void {
    this.selectedPlannedOrderTrackingDataId = plannedOrderTrackingItemId;
    this.lastBarcodeInput = '';
    this.currentInstructionIndex = this.getMostRelevantInstructionIndex();
  }

  public onRemoveOrderNumber(orderNumber: string): void {
    this.barcodeInput = 'r:' + orderNumber;
    this.processBarcode();
  }

  public onCancelOrderNumber(orderNumber: string): void {
    this.barcodeInput = this.cancelPrefix + orderNumber;
    this.processBarcode();
  }

  get allInstructions(): LoadingInstruction[] {
    return this.activeInstructions.concat(this.plannedInstructions);
  }
}
