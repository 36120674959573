




































import Vue from 'vue';
import axios from 'axios';
import Color from '@/models/Color';
import {
  SmartTable,
  Config,
  State,
  Column,
  Sorting,
  PagingOptions,
  Filter
} from 'rey-vue-smarttable';
import { PlannedOrderStateDropdown } from '@/models/PlannedOrderState';

export default Vue.extend({
  name: 'WbtManualAssemblyOverview',
  components: { SmartTable },
  data() {
    return {
      pollingInterval: 2000,
      pollingIntervalId: -1,
      updateDate: undefined as undefined | Date,
      state: new State(new Sorting('sortOrder', 'ascending'), [
        new Filter('releasedForProduction', 'boolean', 'equals', [true]),
        new Filter('plannedOrderState', 'numeric', 'contains', [0, 1]),
        new Filter('loadingStationGroup', 'numeric', 'equals', [4]),
        new Filter('isCancelled', 'boolean', 'equals', [false])
      ]),
      smartTableConfig: new Config(
        (row) => row.orderId,
        [
          new Column({
            title: this.$t(
              'views.stations.planning.plannedOrders.tableHeaders.sortOrder'
            ).toString(),
            fieldName: 'sortOrder',
            fieldType: 'numeric'
          }),
          new Column({
            title: this.$t('views.stations.planning.plannedOrders.tableHeaders.orderId').toString(),
            fieldName: 'orderId',
            fieldType: 'string'
          }),
          new Column({
            title: this.$t(
              'views.stations.planning.plannedOrders.tableHeaders.rfidScanId'
            ).toString(),
            fieldName: 'rfidScanId',
            fieldType: 'string'
          }),
          new Column({
            title: this.$t(
              'views.stations.planning.plannedOrders.tableHeaders.orderNumber'
            ).toString(),
            fieldName: 'orderNumber',
            fieldType: 'string'
          }),
          new Column({
            title: this.$t(
              'views.stations.planning.plannedOrders.tableHeaders.sectorAndTrayNumber'
            ).toString(),
            fieldName: 'sectorAndTrayNumber',
            fieldType: 'string'
          }),
          new Column({
            title: this.$t(
              'views.stations.planning.plannedOrders.tableHeaders.periodNumber'
            ).toString(),
            fieldName: 'periodNumber',
            fieldType: 'numeric'
          }),
          new Column({
            title: this.$t(
              'views.stations.planning.plannedOrders.tableHeaders.startCoating'
            ).toString(),
            fieldName: 'startCoating',
            fieldType: 'string'
          }),
          new Column({
            title: this.$t('views.stations.planning.plannedOrders.tableHeaders.m2Order').toString(),
            fieldName: 'm2Order',
            fieldType: 'string'
          }),
          new Column({
            title: this.$t(
              'views.stations.planning.plannedOrders.tableHeaders.plannedFlybarNumber'
            ).toString(),
            fieldName: 'plannedFlybarNumber',
            fieldType: 'numeric'
          }),
          new Column({
            title: this.$t(
              'views.stations.planning.plannedOrders.tableHeaders.loadingStationGroup'
            ).toString(),
            fieldName: 'loadingStationGroup',
            fieldType: 'string'
          }),
          new Column({
            title: this.$t(
              'views.stations.planning.plannedOrders.tableHeaders.innerColor'
            ).toString(),
            fieldName: 'innerColor',
            fieldType: 'string'
          }),
          new Column({
            title: this.$t(
              'views.stations.planning.plannedOrders.tableHeaders.outerColor'
            ).toString(),
            fieldName: 'outerColor',
            fieldType: 'string'
          }),
          new Column({
            title: this.$t(
              'views.stations.planning.plannedOrders.tableHeaders.releasedForProduction'
            ).toString(),
            fieldName: 'releasedForProduction',
            fieldType: 'boolean'
          }),
          new Column({
            title: this.$t(
              'views.stations.planning.plannedOrders.tableHeaders.plannedOrderState'
            ).toString(),
            fieldName: 'plannedOrderState',
            fieldType: 'dropdown',
            dropdownOptions: PlannedOrderStateDropdown()
          }),
          new Column({
            title: this.$t(
              'views.stations.planning.plannedOrders.tableHeaders.isCancelled'
            ).toString(),
            fieldName: 'isCancelled',
            fieldType: 'boolean'
          })
        ],
        new Sorting('sortOrder', 'ascending'),
        new PagingOptions(25),
        undefined,
        undefined,
        []
      ).withEfApiActions(axios, 'PlannedOrder'),
      selected: undefined as Color | undefined
    };
  },

  mounted() {
    this.pollingIntervalId = setInterval(() => {
      // @ts-ignore
      this.$refs.smartTable.reload(true);

      this.updateDate = new Date();
    }, this.pollingInterval);
    this.updateDate = new Date();
  },
  beforeDestroy() {
    clearInterval(this.pollingIntervalId);
  }
});
