





































































import { Vue } from 'vue-property-decorator';
import systemParameterService from '@/services/SystemParameterService';
import PlannedOrderTrackingDataItem from '@/models/PlannedOrderTrackingDataItem';
import PlannedOrderTrackingDataItemVisualization from '@/components/PlannedOrderTrackingDataItemVisualization.vue';

export default Vue.extend({
  name: 'PlannedFlybarVisualization',
  components: { PlannedOrderTrackingDataItemVisualization },
  props: ['plannedFlybarItem', 'activeItemId', 'maxHeight'],
  data: () => {
    return {
      elementWidth: 0,
      pxPerMilli: 1,
      flybarWidth: 0,
      flybarHeight: 0,
      flybarClearanceTop: 0,
      flybarClearanceBottom: 0,
      flybarClearanceLeft: 0,
      flybarClearanceRight: 0,
      isResizing: false
    };
  },

  created() {
    window.addEventListener('resize', this.handleResize);
  },

  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },

  async mounted() {
    this.flybarWidth = await systemParameterService.getSystemParameterNumber('FlybarWidth');
    this.flybarHeight = await systemParameterService.getSystemParameterNumber('FlybarHeight');
    this.flybarClearanceBottom = await systemParameterService.getSystemParameterNumber(
      'FlybarClearanceBottom'
    );
    this.flybarClearanceTop = await systemParameterService.getSystemParameterNumber(
      'FlybarClearanceTop'
    );
    this.flybarClearanceLeft = await systemParameterService.getSystemParameterNumber(
      'FlybarClearanceLeft'
    );
    this.flybarClearanceRight = await systemParameterService.getSystemParameterNumber(
      'FlybarClearanceRight'
    );
    this.handleResize();
  },

  methods: {
    didSelectItem(item: PlannedOrderTrackingDataItem) {
      this.$emit('didSelectPlannedOrderTrackingItem', item.plannedOrderTrackingDataId);
    },
    mmToPx(valueInPx: number): number {
      return Math.floor(valueInPx * this.pxPerMilli);
    },

    itemIndex(item: PlannedOrderTrackingDataItem) {
      return this.plannedFlybarItem.items.indexOf(item) + 1;
    },

    handleResize() {
      this.isResizing = true;
      setTimeout(() => {
        const element = document.getElementById('flybar-container');
        if (!element) {
          return;
        }
        this.elementWidth = Math.floor(element.getBoundingClientRect().width * 0.99); // 1% safety padding

        if (this.flybarWidth > 0) {
          this.pxPerMilli = this.elementWidth / this.flybarWidth;

          if (this.maxHeight && this.mmToPx(this.flybarHeight) > this.maxHeight) {
            this.pxPerMilli = this.maxHeight / this.flybarWidth;
          }
        } else {
          this.pxPerMilli = 1;
        }
        this.isResizing = false;
      }, 10);
    },

    itemsInColumn(column: number): PlannedOrderTrackingDataItem[] {
      if (!this.plannedFlybarItem) {
        return [];
      }

      const items = this.plannedFlybarItem.items.filter(
        (item: PlannedOrderTrackingDataItem) => item.positionX == column
      );

      return items.sort((x: PlannedOrderTrackingDataItem) => x.positionY);
    }
  },
  computed: {
    columns(): number[] {
      if (!this.plannedFlybarItem) {
        return [];
      }

      return (
        [
          ...new Set(
            this.plannedFlybarItem.items.map((item: PlannedOrderTrackingDataItem) => item.positionX)
          )
        ] as number[]
      ).sort((a: number, b: number) => a - b);
    }
  },

  watch: {
    plannedFlybarItem(newValue, oldValue) {
      if (newValue && !oldValue) {
        setTimeout(() => this.handleResize(), 1);
      }
    }
  }
});
