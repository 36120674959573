import i18n from '@/languages/i18n';

export enum PlannedOrderState {
  Planned = 0,
  Started = 1,
  Completed = 2,
  Cancelled = 3
}

export const PlannedOrderStateDropdown = () => [
  {
    id: PlannedOrderState.Planned,
    title: i18n.t('dropdowns.plannedOrderState.planned').toString()
  },
  {
    id: PlannedOrderState.Started,
    title: i18n.t('dropdowns.plannedOrderState.started').toString()
  },
  {
    id: PlannedOrderState.Completed,
    title: i18n.t('dropdowns.plannedOrderState.completed').toString()
  },
  {
    id: PlannedOrderState.Cancelled,
    title: i18n.t('dropdowns.plannedOrderState.cancelled').toString()
  }
];

export default PlannedOrderState;
