









































































import Vue, { PropType } from 'vue';
import AuthenticationType from '@/models/AuthenticationType';

interface FullAuthenticationType {
  id: AuthenticationType;
  title: string;
}

export default Vue.extend({
  name: 'EnterPasswordDialog',
  props: {
    authenticationType: {
      type: Object as PropType<FullAuthenticationType | undefined>,
      required: false
    }
  },
  components: {},
  data: () => {
    return {
      showModal: false,
      password: '',
      showError: false
    };
  },
  created() {
    document.addEventListener('keyup', (evt) => {
      if (evt.key === 'Escape') {
        this.cancel();
      }
    });
  },
  methods: {
    reset() {
      this.password = '';
    },
    show(showError = false) {
      this.showModal = true;
      this.showError = showError;
      //@ts-ignore
      setTimeout(() => this.$refs.password.focus(), 10);
    },
    cancel() {
      this.reset();
      this.showModal = false;
      this.$emit('didCancel');
    },
    usePassword() {
      this.showModal = false;
      this.$emit('didEnter', this.password, this.authenticationType?.id);
      this.reset();
    }
  },
  computed: {
    formIsValid() {
      return this.password.trim().length > 0;
    }
  }
});
