




















































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import RobotConfigurationStationState from '@/models/RobotConfigurationState';
import axios from 'axios';
import RobotConfigurationInstruction from '@/models/RobotConfigurationInstruction';
import RobotConfigurationItem from '@/components/RobotConfigurationItem.vue';
import fieldDefinitionLoader from '@/services/FieldDefinitionLoader';
import Modality from '@/models/Modality';
import Color from '@/models/Color';
import FieldDefinition from '@/models/FieldDefinition';

declare interface FieldValueUpdateInput {
  fieldDefinitionId: string;
  entityForeignKey: number;
  value: string | undefined;
}

declare interface GeometryColorParameterSuggestionUpdateInput {
  geometryColorParameterId: number;
  adoptSuggestion: boolean;
  fieldValueUpdates: FieldValueUpdateInput[];
}

// @ts-ignore
import { Alert } from 'rey-vue-common';

@Component({
  components: { RobotConfigurationItem, Alert }
})
export default class RobotConfigurationStation extends Vue {
  public activeState: RobotConfigurationStationState = {
    isReady: false
  } as RobotConfigurationStationState;
  public activeInstructions: RobotConfigurationInstruction[] = [];
  public currentInstructionIndex = 0;
  public colors: Color[] = [];
  public modalities: Modality[] = [];
  public isSending = false;
  public showSuccessMessage = false;
  public showModalitiesSuccessMessage = false;
  public errorMessage: string | undefined;

  public async mounted(): Promise<void> {
    // @ts-ignore
    this.$messagesService.$on('CurrentRobotConfigurationStationState', this.updateStationState);

    this.colors = await fieldDefinitionLoader.loadColors();
    await this.loadModalities(false);
  }

  public async unmounted(): Promise<void> {
    // @ts-ignore
    this.$messagesService.$off('CurrentRobotConfigurationStationState', this.updateStationState);
  }

  public async loadModalities(showSuccess: boolean): Promise<void> {
    this.modalities = await fieldDefinitionLoader.loadModalities();
    if (showSuccess) {
      this.showModalitiesSuccessMessage = true;
      setTimeout(() => (this.showModalitiesSuccessMessage = false), 2000);
    }
  }

  get currentStationNumber(): number {
    // @ts-ignore
    return this.$route.meta.stationNumber;
  }

  get isReady(): boolean {
    return this.activeState && this.activeState.isReady && this.activeInstructions != null;
  }

  public onFieldValueChanged(
    instructionIndex: number,
    fieldDefinition: FieldDefinition,
    value: string | undefined
  ): void {
    const instruction = this.activeInstructions[instructionIndex];
    const existingValue = instruction.fieldValues.find(
      (x) => x.fieldDefinitionId == fieldDefinition.fieldDefinitionId
    );
    if (existingValue !== undefined) {
      existingValue.value = value;
    } else {
      instruction.fieldValues.push({
        fieldValueId: -1,
        fieldDefinitionId: fieldDefinition.fieldDefinitionId,
        entityForeignKey: instruction.geometryColorParameter.geometryColorParameterId,
        value: value
      });
    }
  }

  public onAdoptSuggestionChanged(instructionIndex: number, adoptSuggestion: boolean): void {
    const instruction = this.activeInstructions[instructionIndex];
    instruction.adoptSuggestion = adoptSuggestion;
  }

  private async updateStationState(currentState: RobotConfigurationStationState): Promise<void> {
    // @ts-ignore
    if (this.$route.meta.stationNumber !== currentState.stationNumber) {
      return;
    }
    var needsReloadingInstructions =
      this.activeState === undefined ||
      currentState.flybarNumber !== this.activeState?.flybarNumber;
    this.activeState = currentState;
    this.showSuccessMessage = false;
    this.errorMessage = undefined;

    if (currentState.isReady && needsReloadingInstructions) {
      await this.loadInstruction(currentState.trackingNumber, currentState.cycleNumber);
    }
  }

  public async sendValues(): Promise<void> {
    this.isSending = true;
    const data = {
      cycleNumber: this.activeState?.cycleNumber,
      stationNumber: this.activeState?.stationNumber,
      trackingDataId: this.activeState?.trackingNumber,
      geometryColorParameterSuggestionUpdates: this.createGeometryColorParameterSuggestionUpdates()
    };
    await axios
      .post('/api/actions/UpdateRobotConfigurationInstruction/', data)
      .then(() => {
        this.errorMessage = undefined;
        this.activeInstructions = [];
        this.activeState = { isReady: false } as RobotConfigurationStationState;
        this.showSuccessMessage = true;
        setTimeout(() => (this.showSuccessMessage = false), 2000);
      })
      .catch((response) => {
        this.showSuccessMessage = false;
        this.errorMessage = response.response.message || response.response.statusText;
      })
      .finally(() => (this.isSending = false));
  }

  private createGeometryColorParameterSuggestionUpdates(): GeometryColorParameterSuggestionUpdateInput[] {
    const result = [] as GeometryColorParameterSuggestionUpdateInput[];
    this.activeInstructions.forEach((instruction, index) => {
      if (instruction.geometryColorParameter.isSuggestion) {
        result.push({
          geometryColorParameterId: instruction.geometryColorParameter.geometryColorParameterId,
          adoptSuggestion: instruction.adoptSuggestion,
          fieldValueUpdates: this.createFieldValueUpdates(index)
        });
      }
    });
    return result;
  }

  private createFieldValueUpdates(index: number): FieldValueUpdateInput[] {
    const result = [] as FieldValueUpdateInput[];
    const instruction = this.activeInstructions[index];
    if (instruction.geometryColorParameter.isSuggestion) {
      instruction.fieldValues.forEach((value) => {
        result.push({
          value: value.value,
          entityForeignKey: value.entityForeignKey,
          fieldDefinitionId: value.fieldDefinitionId
        });
      });
    }
    return result;
  }

  private async loadInstruction(trackingNumber: number, cycleNumber: number): Promise<void> {
    if (!trackingNumber || trackingNumber === 0) {
      this.activeInstructions = [] as RobotConfigurationInstruction[];
      return;
    }

    await axios
      .post('/api/actions/GetRobotConfigurationInstruction/', {
        StationNumber: this.currentStationNumber,
        TrackingNumber: trackingNumber,
        CycleNumber: cycleNumber
      })
      .then((response) => {
        this.activeInstructions = response.data;
        this.currentInstructionIndex =
          this.currentInstructionIndex >= 0 &&
          this.currentInstructionIndex <= this.activeInstructions.length - 1
            ? this.currentInstructionIndex
            : this.activeInstructions.length - 1;
      })
      .catch(() => (this.activeInstructions = [] as RobotConfigurationInstruction[]));
  }
}
