

























import { Vue } from 'vue-property-decorator';
import ListPlannedTrackingData from '@/components/ListPlannedTrackingData.vue';
import PlannedTrackingDataDetails from '@/components/PlannedTrackingDataDetails.vue';
import PlannedTrackingDataItem from '@/models/PlannedTrackingDataItem';
import PlannedOrderTrackingDataItem from '@/models/PlannedOrderTrackingDataItem';

export default Vue.extend({
  name: 'PlannedUpcomingFlybars',
  components: { PlannedTrackingDataDetails, ListPlannedTrackingData },
  props: [
    'plannedTrackingData',
    'activeOrderId',
    'selectedPlannedTrackingDataId',
    'selectedPlannedOrderTrackingDataItemId',
    'isScanning',
    'activeInstructions'
  ],
  data: () => {
    return {};
  },

  methods: {
    didSelectItem(plannedTrackingDataId: string) {
      this.$emit('didSelectPlannedTrackingDataItem', plannedTrackingDataId);
    },

    didSelectOrderTrackingItem(plannedOrderTrackingDataId: string | undefined) {
      this.$emit('didSelectPlannedOrderTrackingDataItem', plannedOrderTrackingDataId);
    },
    isActive(plannedTrackingData: PlannedTrackingDataItem) {
      if (!this.activeOrderId) return false;

      return (
        plannedTrackingData.items.find(
          (x: PlannedOrderTrackingDataItem) => x.orderId == this.activeOrderId
        ) !== undefined
      );
    },
    onCancelOrderNumber(orderNumber: string) {
      this.$emit('cancelOrderNumber', orderNumber);
    },

    onRemoveOrderNumber(orderNumber: string) {
      this.$emit('removeOrderNumber', orderNumber);
    }
  },

  computed: {
    selectedItem() {
      if (!this.selectedPlannedTrackingDataId) {
        return undefined;
      }
      return this.plannedTrackingData.find(
        (x: PlannedTrackingDataItem) =>
          x.plannedTrackingDataId == this.selectedPlannedTrackingDataId
      );
    }
  },

  watch: {
    selectedPlannedTrackingDataId(newValue, oldValue) {
      if (newValue && oldValue && newValue != oldValue) {
        this.didSelectOrderTrackingItem(undefined);
      }
    }
  }
});
