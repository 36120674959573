









































import Vue from 'vue';

export default Vue.extend({
  name: 'Pagination',
  props: ['totalCount', 'skipped', 'taken', 'maxItems'],
  components: {},
  methods: {
    lastPage() {
      this.$emit('didChangeSkip', (Math.ceil(this.totalCount / this.taken) - 1) * this.taken);
    },
    nextPage() {
      this.$emit('didChangeSkip', this.skipped + this.taken);
    },
    previousPage() {
      this.$emit('didChangeSkip', this.skipped - this.taken);
    },
    firstPage() {
      this.$emit('didChangeSkip', 0);
    },
    goto(page: number) {
      this.$emit('didChangeSkip', page);
    }
  },
  computed: {
    maxPage(): number {
      return Math.ceil(this.totalCount / this.taken);
    },
    pages() {
      //@ts-ignore
      const allIndexes = [...Array(this.maxPage).keys()];
      const maxItems = Math.min(this.maxItems ?? 10, 5);
      if (allIndexes.length > maxItems) {
        const currentPage = this.skipped / this.taken;
        const minimizedIndexes = [currentPage];
        let i = 0;
        let lastCheckedLeft = true;
        while (minimizedIndexes.length < maxItems) {
          if (lastCheckedLeft) {
            const rightCandidate = currentPage + i;
            if (
              allIndexes.indexOf(rightCandidate) > -1 &&
              minimizedIndexes.indexOf(rightCandidate) === -1
            ) {
              minimizedIndexes.push(rightCandidate);
            }
          } else {
            const leftCandidate = currentPage - i;
            if (
              allIndexes.indexOf(leftCandidate) > -1 &&
              minimizedIndexes.indexOf(leftCandidate) === -1
            ) {
              minimizedIndexes.push(leftCandidate);
            }
          }
          if (!lastCheckedLeft) {
            i++;
          }
          if (i > allIndexes.length) {
            // something bad has happened?
            break;
          }
          lastCheckedLeft = !lastCheckedLeft;
        }
        return minimizedIndexes.map((x) => x * this.taken).sort((a, b) => a - b);
      } else {
        return allIndexes.map((x) => x * this.taken);
      }
    }
  }
});
