export enum ScanState {
  BarcodeSuccessfullyScanned = 0,
  BarcodeNotFound = 1,
  PlcNotReady = 2,
  PlcReturnedErrorAfterSendingData = 3,
  UnexpectedError = 4,
  NoDataRequestedYetFromThePlc = 5,
  ArticleNotFound = 6,
  BarcodeSuccessfullyScannedWithDefaultArticle = 7,
  MultipleBarcodesPerFlybarNotAllowed = 8,
  ProductionParametersInconsistent = 9,
  OrderWasCancelled = 10,
  MaxAmountParameterSetsExceeded = 11,
  BarcodeSuccessfullyRemoved = 12
}
