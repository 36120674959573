import Vue from 'vue';
import VueI18n, { LocaleMessageObject } from 'vue-i18n';
// @ts-ignore
import resourceLoader from '@/services/ResourceLoader';
// @ts-ignore
import Language from '@/models/Language';
import Resource from '@/models/Resource';

const defaultCulture = 'de';
const availableLanguages: Language[] = [];
let activeLanguageCulture = 'de';

Vue.use(VueI18n);
const currentLanguageLocalStorageKey = 'currentLanguage';
const i18n = new VueI18n({
  locale: defaultCulture
});

async function loadLanguage(language: Language): Promise<void> {
  const resources = await resourceLoader.loadResources(language.key);
  const localeMessageObject = {} as LocaleMessageObject;
  resources.forEach((x: Resource) => (localeMessageObject[x.resourceId] = x.value));
  i18n.setLocaleMessage(language.culture, localeMessageObject);
  i18n.locale = language.culture;
  activeLanguageCulture = language.culture;
}

export async function initializeI18n(): Promise<void> {
  const activeLanguages = await resourceLoader.loadActiveLanguages();
  availableLanguages.splice(0, availableLanguages.length);
  activeLanguages.forEach((x: Language) => availableLanguages.push(x));
  const activeLanguage =
    activeLanguages.find(
      (x: Language) => x.key == localStorage.getItem(currentLanguageLocalStorageKey)
    ) ??
    activeLanguages.find((x: Language) => x.culture == defaultCulture) ??
    activeLanguages[0];
  await loadLanguage(activeLanguage);
}

export async function reloadActiveLanguage(): Promise<void> {
  const activeLanguage = availableLanguages.find((x) => x.culture === activeLanguageCulture);
  if (!activeLanguage) {
    return;
  }

  await loadLanguage(activeLanguage);
}

export function activateLanguage(language: Language): void {
  localStorage.setItem(currentLanguageLocalStorageKey, language.key);
  window.location.reload();
}

export function getAvailableLanguages(): Language[] {
  return availableLanguages;
}

export default i18n;
