import axios from 'axios';
import SystemParameter from '@/models/SystemParameter';

class SystemParameterService {
  private state = {
    parameters: undefined as Promise<SystemParameter[]> | undefined,
    isLoaded: false
  };

  resetData() {
    this.state.parameters = undefined;
    this.state.isLoaded = false;
  }

  async getSystemParameterBool(parameterDefinitionId: string): Promise<boolean> {
    const parameters = await this.getSystemParameters();
    const value =
      parameters.find((x) => x.parameterDefinitionId === parameterDefinitionId)?.value ?? '';

    return value.toLowerCase() === 'true';
  }

  async getSystemParameterNumber(parameterDefinitionId: string): Promise<number> {
    const parameters = await this.getSystemParameters();
    const value =
      parameters.find((x) => x.parameterDefinitionId === parameterDefinitionId)?.value ?? '';
    if (value !== '') {
      return parseInt(value);
    }

    return 0;
  }

  async getSystemParameterString(parameterDefinitionId: string): Promise<string> {
    const parameters = await this.getSystemParameters();
    return parameters.find((x) => x.parameterDefinitionId === parameterDefinitionId)?.value ?? '';
  }

  async getSystemParameters(): Promise<SystemParameter[]> {
    if (!this.state.parameters) {
      this.state.parameters = this.loadSystemParameters();
    }

    return this.state.parameters;
  }

  async loadSystemParameters(): Promise<SystemParameter[]> {
    const result = await axios.get('/api/actions/GetSystemParameters/');
    return result.data;
  }

  async updateParameter(parameterDefinitionId: string, newValue: string) {
    await axios
      .post('/api/actions/SetSystemParameter/', {
        ParameterDefinitionId: parameterDefinitionId,
        Value: newValue
      })
      .catch((error) => console.error(error));
  }
}
const instance = new SystemParameterService();

export default instance;
