import i18n from '@/languages/i18n';

export enum StationType {
  Loading = 0,
  Masking = 1,
  FlybarPreview = 2,
  Unloading = 3,
  RoboterConfiguration = 5,
  Planning = 6,
  Grafana = 7
}

export const StationTypeDropdown = () => [
  { id: StationType.Loading, title: i18n.t('dropdowns.stationTypes.loading').toString() },
  { id: StationType.Masking, title: i18n.t('dropdowns.stationTypes.masking').toString() },
  {
    id: StationType.FlybarPreview,
    title: i18n.t('dropdowns.stationTypes.flybarPreview').toString()
  },
  { id: StationType.Unloading, title: i18n.t('dropdowns.stationTypes.unloading').toString() },
  {
    id: StationType.RoboterConfiguration,
    title: i18n.t('dropdowns.stationTypes.roboterConfiguration').toString()
  },
  {
    id: StationType.Planning,
    title: i18n.t('dropdowns.stationTypes.planning').toString()
  },
  {
    id: StationType.Grafana,
    title: i18n.t('views.reporting.dashboard.title').toString()
  }
];

export default StationType;
