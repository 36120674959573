



























































































































































import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import axios from 'axios';
import UnloadingInstruction from '@/models/UnloadingInstruction';
import UnloadingStationState from '@/models/UnloadingStationState';
import InstructionViewer from '@/components/InstructionViewer.vue';
// @ts-ignore
import VueBarcode from 'vue-barcode';
import AdditionalParameterValue from '@/views/stations/AdditionalParameterValue.vue';
import StationType from '@/models/StationType';
import StationParameter from '@/models/StationParameter';

@Component({
  components: { AdditionalParameterValue, barcode: VueBarcode, InstructionViewer }
})
export default class UnloadingStation extends Vue {
  public activeState: UnloadingStationState = { stationNumber: 0 } as UnloadingStationState;
  public activeInstructions: UnloadingInstruction[] = [] as UnloadingInstruction[];
  public currentInstructionIndex = 0;
  public isLoading = true;
  public stationParameters = [] as StationParameter[];

  public created(): void {
    window.addEventListener('keydown', this.handleKeyDown);
  }

  public beforeDestroyed(): void {
    window.removeEventListener('keydown', this.handleKeyDown);
  }

  public async mounted(): Promise<void> {
    await this.loadStationParameters();
    // @ts-ignore
    this.$messagesService.$on('CurrentUnloadingStationState', this.updateStationState);
  }

  public unmounted(): void {
    // @ts-ignore
    this.$messagesService.$off('CurrentUnloadingStationState', this.updateStationState);
  }

  // computed values
  get currentStationNumber(): number {
    // @ts-ignore
    return this.$route.meta.stationNumber;
  }

  @Watch('activeState')
  private onActiveStateChanged(state: UnloadingStationState) {
    if (state.trackingNumber === 0) {
      this.activeInstructions = [] as UnloadingInstruction[];
      return;
    }
    this.loadInstruction(state);
  }

  private async updateStationState(currentState: UnloadingStationState) {
    // @ts-ignore
    if (this.$route.meta.stationNumber !== currentState.stationNumber) {
      return;
    }
    this.activeState = currentState;
  }

  private async loadInstruction(state: UnloadingStationState) {
    await axios
      .post('/api/actions/GetUnloadingInstruction/', {
        TrackingNumber: state.trackingNumber,
        CycleNumber: state.cycleNumber
      })
      .then((response) => {
        this.activeInstructions = response.data;
      })
      .catch(() => (this.activeInstructions = [] as UnloadingInstruction[]));
  }

  private handleKeyDown(e: KeyboardEvent) {
    if (e.key === 'ArrowUp') {
      e.preventDefault();
      this.setCurrentIndexOffset(1);
    } else if (e.key === 'ArrowDown') {
      e.preventDefault();
      this.setCurrentIndexOffset(-1);
    }
  }

  public setCurrentIndexOffset(offset: number): void {
    this.currentInstructionIndex =
      (this.currentInstructionIndex + offset + this.activeInstructions.length) %
      this.activeInstructions.length;
  }

  private async loadStationParameters() {
    try {
      this.isLoading = true;
      this.stationParameters = (
        await axios.post<StationParameter[]>('/api/actions/GetStationParameters/', {
          StationNumber: this.currentStationNumber,
          StationType: StationType.Unloading
        })
      ).data;
    } finally {
      this.isLoading = false;
    }
  }

  public get reportTemplatePath(): string | undefined {
    return this.getStringParameter('UnloadingStationQualityReportTemplatePath');
  }

  public get showQualityReportButton(): boolean | undefined {
    return this.getBoolParameter('UnloadingStationShowQualityReportButton');
  }

  public getStringParameter(parameterName: string): string {
    let value = this.stationParameters.find(
      (x: StationParameter) => x.parameterDefinitionId === parameterName
    );

    return value?.value ?? '';
  }

  public getBoolParameter(parameterName: string): boolean {
    let value = this.stationParameters.find(
      (x: StationParameter) => x.parameterDefinitionId === parameterName
    );

    return value?.value === 'True';
  }
}
