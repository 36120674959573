






















































import Vue from 'vue';
import InstructionViewer from '@/components/InstructionViewer.vue';
import MaskingInstruction from '@/models/MaskingInstruction';
import ActiveMaskingInstructionDetails from '@/components/MaskingViews/SubViews/ActiveMaskingInstructionDetails.vue';

export default Vue.extend({
  name: 'DefaultMaskingView',
  components: {
    InstructionViewer,
    ActiveMaskingInstructionDetails
  },
  props: ['activeState', 'activeInstructions'],
  data() {
    return {
      currentInstructionIndex: 0
    };
  },
  created() {
    window.addEventListener('keydown', this.handleKeyDown);
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.handleKeyDown);
  },
  methods: {
    handleKeyDown(e: KeyboardEvent) {
      if (e.key === 'ArrowUp') {
        e.preventDefault();
        this.setCurrentIndexOffset(1);
      } else if (e.key === 'ArrowDown') {
        e.preventDefault();
        this.setCurrentIndexOffset(-1);
      }
    },

    setCurrentIndexOffset(offset: number) {
      this.currentInstructionIndex =
        (this.currentInstructionIndex + offset + this.activeInstructions.length) %
        this.activeInstructions.length;
    }
  },
  watch: {
    activeInstructions(newValue: MaskingInstruction[]) {
      if (
        this.activeInstructions.length !== newValue.length ||
        this.currentInstructionIndex == undefined ||
        this.activeInstructions[this.currentInstructionIndex] == undefined ||
        this.activeInstructions[this.currentInstructionIndex].instructions.length !==
          newValue[this.currentInstructionIndex].instructions.length
      ) {
        this.currentInstructionIndex = 0;
      }
    }
  }
});
