import axios from 'axios';
import Resource from '@/models/Resource';
import Language from '@/models/Language';

class ResourceLoader {
  async loadActiveLanguages(): Promise<Language[]> {
    const response = await axios.get('/api/Language', {
      params: {
        equals: 'isActive=1'
      }
    });

    return response.data.entities;
  }
  async loadResources(languageKey: string): Promise<Resource[]> {
    const response = await axios.get('/api/Resource', {
      params: {
        equals: 'languageKey=' + languageKey
      }
    });

    return response.data.entities;
  }
}

export default new ResourceLoader();
