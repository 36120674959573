import axios from 'axios';
import AuthenticationType from '@/models/AuthenticationType';
import SystemParameterService from '@/services/SystemParameterService';

class AuthenticationService {
  private _isAuthenticated = false;
  private _canAuthenticate = true;

  constructor() {
    SystemParameterService.getSystemParameterBool('UsePasswords').then((result) => {
      if (!result) {
        this._canAuthenticate = false;
      }
    });
  }
  public get canAuthenticate() {
    return this._canAuthenticate;
  }
  public get isAuthenticated() {
    if (!this._canAuthenticate) {
      return true;
    }
    return this._isAuthenticated;
  }

  public logout() {
    this._isAuthenticated = false;
  }

  public authenticate(password: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/Authentications/validate', {
          password: password,
          authenticationType: AuthenticationType.Admin
        })
        .then((_) => {
          this._isAuthenticated = true;
          resolve(true);
        })
        .catch((_) => {
          this._isAuthenticated = false;
          reject();
        });
    });
  }
}

export default new AuthenticationService();
