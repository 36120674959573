import Vue from 'vue';
import App from './App.vue';
import router from './router';
import 'bootstrap';
import axios from 'axios';
import StickyActionBar from '@/components/StickyActionBar.vue';
import { ContentCard } from 'rey-smartmes-ui';
import { HttpActionsPlugin, MessagesServicePlugin } from 'rey-smartmes-clients';
import i18n, { initializeI18n } from '@/languages/i18n';

import 'devextreme/dist/css/dx.light.css';
import './assets/shims.css';

(async () => {
  await initializeI18n();

  Vue.use(HttpActionsPlugin, { axios });
  Vue.use(MessagesServicePlugin, {
    events: [
      'CurrentMaskingStationState',
      'CurrentUnloadingStationState',
      'CurrentLoadingStationState',
      'CurrentCoatingCabinStationState',
      'CurrentFlybarPreviewStationState',
      'CurrentRobotConfigurationStationState',
      'ProcessBarcodeResponseState'
    ]
  });

  Vue.config.productionTip = false;
  Vue.component('sticky-actionbar', StickyActionBar);
  Vue.component('content-card', ContentCard);

  new Vue({
    i18n,
    router: router(),
    created() {
      // @ts-ignore
      this.$messagesService.start();
    },
    render: (h) => h(App)
  }).$mount('#app');
})();
