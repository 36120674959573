
























import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import EnterPasswordDialog from '@/components/EnterPasswordDialog.vue';
import { AuthenticationType, AuthenticationTypeDropdown } from '@/models/AuthenticationType';
import AuthenticationService from '@/services/AuthenticationService';

@Component({
  components: { EnterPasswordDialog }
})
export default class LanguageSelector extends Vue {
  public AuthenticationTypeDropdown = AuthenticationTypeDropdown();
  public AuthenticationType = AuthenticationType;
  public AuthenticationService = AuthenticationService;

  get showAdminLogin() {
    return this.$route.meta?.showAdminLogin && AuthenticationService.canAuthenticate;
  }

  public submitPassword(password: string) {
    AuthenticationService.authenticate(password).catch(() => {
      //@ts-ignore
      this.$refs.passwordDialog.show(true);
    });
  }
}
