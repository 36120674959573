import i18n from '@/languages/i18n';

export enum UiControlType {
  Text = 0,
  Numeric = 1,
  Boolean = 2,
  RetentionPeriod = 3,
  ColorDropdown = 4,
  None = 5,
  Timestamp = 6,
  DateOnly = 7,
  Modality = 8,
  Icons = 9
}

export const UiControlTypeDropdown = () => [
  {
    id: UiControlType.Text,
    title: i18n.t('dropdowns.uiControlTypes.text').toString()
  },
  {
    id: UiControlType.Numeric,
    title: i18n.t('dropdowns.uiControlTypes.numeric').toString()
  },
  {
    id: UiControlType.Boolean,
    title: i18n.t('dropdowns.uiControlTypes.boolean').toString()
  },
  {
    id: UiControlType.RetentionPeriod,
    title: i18n.t('dropdowns.uiControlTypes.retentionPeriod').toString()
  },
  {
    id: UiControlType.ColorDropdown,
    title: i18n.t('dropdowns.uiControlTypes.colorDropdown').toString()
  },
  {
    id: UiControlType.None,
    title: i18n.t('dropdowns.uiControlTypes.none').toString()
  },
  {
    id: UiControlType.Timestamp,
    title: i18n.t('dropdowns.uiControlTypes.timestamp').toString()
  },
  {
    id: UiControlType.DateOnly,
    title: i18n.t('dropdowns.uiControlTypes.dateOnly').toString()
  },
  {
    id: UiControlType.Modality,
    title: i18n.t('dropdowns.uiControlTypes.modality').toString()
  },
  {
    id: UiControlType.Icons,
    title: i18n.t('dropdowns.uiControlTypes.icons').toString()
  }
];

export default UiControlType;
