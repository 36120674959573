












































































































































































import Vue from 'vue';
import PlannedFlybarVisualization from '@/components/PlannedFlybarVisualization.vue';
import axios from 'axios';
import PlannedTrackingDataItem from '@/models/PlannedTrackingDataItem';
import { GetPlannedTrackingDataResponse } from '@/models/GetPlannedTrackingDataResponse';
import PlannedOrderTrackingDataItem from '@/models/PlannedOrderTrackingDataItem';
import Pagination from '@/components/Pagination.vue';

export default Vue.extend({
  name: 'WBTFlybarOverview',
  components: { Pagination, PlannedFlybarVisualization },
  data() {
    return {
      showPreviewModal: false,
      filterIsReady: true,
      currentSkipped: 0,
      totalCount: 0,
      skipped: 0,
      taken: 0,
      pageSize: 25,
      isLoading: false,
      updateDate: undefined as undefined | Date,
      pollingInterval: 5000,
      pollingIntervalId: -1,
      plannedTrackingData: [] as PlannedTrackingDataItem[],
      selectedPlannedTrackingDataId: undefined as undefined | string
    };
  },

  methods: {
    async handlePagination(skip: number) {
      this.currentSkipped = skip;
      await this.reload();
    },
    async reload() {
      if (this.plannedTrackingData.length === 0) this.isLoading = true;
      await axios
        .post('/api/actions/GetPlannedTrackingData/', {
          skip: this.currentSkipped,
          take: this.pageSize,
          filterIsReady: this.filterIsReady
        })
        .then((response: GetPlannedTrackingDataResponse) => {
          this.skipped = response.data.skipped;
          this.totalCount = response.data.totalCount;
          this.taken = response.data.taken;
          this.plannedTrackingData = response.data.plannedTrackingData;
        })
        .catch(() => (this.plannedTrackingData = []))
        .finally(() => {
          this.isLoading = false;
          this.updateDate = new Date();
        });
    },

    getArticleParameterValue(item: PlannedOrderTrackingDataItem, parameterName: string): unknown {
      const articleParameter = item.articleParameters?.find((x) => x.parameter === parameterName);
      return articleParameter?.value;
    },
    getFirstArticleParameterValue(
      items: PlannedOrderTrackingDataItem[],
      parameterName: string
    ): unknown {
      for (let item of items) {
        const value = this.getArticleParameterValue(item, parameterName);
        if (value != null) {
          return value;
        }
      }
      return null;
    },

    toggleFilterIsReady() {
      this.filterIsReady = !this.filterIsReady;
      this.reload();
    }
  },

  computed: {
    selectedPlannedTrackingData(): PlannedTrackingDataItem | undefined {
      if (!this.selectedPlannedTrackingDataId || this.selectedPlannedTrackingDataId == '') {
        return undefined;
      }

      return this.plannedTrackingData.find(
        (x) => x.plannedTrackingDataId == this.selectedPlannedTrackingDataId
      );
    },

    previewHeight() {
      return Math.floor(window.innerHeight * 0.8);
    },
    previewWidth() {
      return Math.floor(window.innerWidth * 0.8);
    }
  },

  watch: {
    selectedPlannedTrackingDataId(newValue, oldValue) {
      if (newValue != null && oldValue == null) {
        this.showPreviewModal = true;
      }
    },

    showPreviewModal(newValue) {
      if (!newValue) {
        this.selectedPlannedTrackingDataId = undefined;
      }
    }
  },

  async mounted() {
    await this.reload();
    this.pollingIntervalId = setInterval(() => {
      this.reload();
    }, this.pollingInterval);

    document.addEventListener('keyup', (evt) => {
      if (evt.key === 'Escape') {
        this.showPreviewModal = false;
      }
    });
  },
  beforeDestroy() {
    clearInterval(this.pollingIntervalId);
  }
});
