

































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import WBTOrderOverview from '@/components/PlanningViews/WBTOrderOverview.vue';
import axios from 'axios';
import StationType from '@/models/StationType';
import StationParameter from '@/models/StationParameter';

// @ts-ignore
import { Alert } from 'rey-vue-common';
import WBTFlybarOverview from '@/components/PlanningViews/WBTFlybarOverview.vue';
import WBTCompartmentTrolleyOverview from '@/components/PlanningViews/WBTCompartmentTrolleyOverview.vue';
import WbtManualAssemblyOverview from '@/components/PlanningViews/WBTManualAssemblyOverview.vue';

@Component({
  components: {
    WbtManualAssemblyOverview,
    WBTCompartmentTrolleyOverview,
    WBTFlybarOverview,
    WBTOrderOverview,
    Alert
  }
})
export default class RobotConfigurationStation extends Vue {
  public planningViewType = -1;
  public isLoading = true;

  public async mounted(): Promise<void> {
    await this.loadStationParameters();
  }

  private async loadStationParameters() {
    await axios
      .post('/api/actions/GetStationParameters/', {
        StationNumber: this.currentStationNumber,
        StationType: StationType.Planning
      })
      .then((response) => response.data)
      .then((result) => this.loadStationViewType(result))
      .finally(() => (this.isLoading = false));
  }

  private loadStationViewType(parameters: StationParameter[]) {
    let values = parameters.filter((x) => x.parameterDefinitionId === 'PlanningViewType');
    // eslint-disable-next-line
    if (values.some((_) => true)) {
      this.planningViewType = parseInt(values[0].value);
    }
  }

  get currentStationNumber(): number {
    // @ts-ignore
    return this.$route.meta.stationNumber;
  }
}
