export enum PlannedOrderTrackingDataState {
  Planned = 0,
  Scanned = 1,
  FlybarStarted = 2,
  Cancelled = 3
}

export function plannedOrderTrackingDataStateIcon(
  state: PlannedOrderTrackingDataState
): string | undefined {
  switch (state) {
    case PlannedOrderTrackingDataState.Planned:
      return 'fas fa-clock';
    case PlannedOrderTrackingDataState.Scanned:
      return 'fas fa-barcode';
    case PlannedOrderTrackingDataState.FlybarStarted:
      return 'fas fa-check-circle';
    case PlannedOrderTrackingDataState.Cancelled:
      return 'fas fa-times';
  }
  return undefined;
}
